import React, { useRef, useEffect, useState } from 'react';
import './Marquee.css';

const Marquee = ({ bgColor, letterColor, text }) => {
  const marqueeRef = useRef(null);
  const [duration, setDuration] = useState(15); // Default duration in seconds

  useEffect(() => {
    if (marqueeRef.current) {
      const marqueeWidth = marqueeRef.current.scrollWidth;
      const speed = 80; // Speed in pixels per second (adjust as needed)
      const calculatedDuration = marqueeWidth / speed;
      setDuration(calculatedDuration);
    }
  }, [text]);

  return (
    <div className="marquee" style={{ backgroundColor: bgColor }}>
      <div
        className="marquee-content"
        ref={marqueeRef}
        style={{ animationDuration: `${duration}s` }}
      >
        {text.map((item, index) => (
          <span
            key={index}
            className={index % 2 === 0 ? 'solid' : 'outline'}
            style={{
              color: index % 2 === 0 ? letterColor : 'transparent',
              WebkitTextStroke: index % 2 === 1 ? `1px ${letterColor}` : 'none',
            }}
          >
            {item}
          </span>
        ))}
        {text.map((item, index) => (
          <span
            key={index + text.length}
            className={index % 2 === 0 ? 'solid' : 'outline'}
            style={{
              color: index % 2 === 0 ? letterColor : 'transparent',
              WebkitTextStroke: index % 2 === 1 ? `1px ${letterColor}` : 'none',
            }}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Marquee;
