import RobotImg from "../../../../assets/quadrupedrobot.png"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { contacts } from './data'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import CursorAnimation from '../../../../CursorAnimation'
import SmallImage from "../../Small-Image/Small-Image";
import Footer from "../../../../Footer/Footer";

const ProjectShowcase = ()=>{

    const [current , setCurrent ] = useState(0)
    const overviewRef = useRef(null)
    const vitRef = useRef(null)
    const contactRef = useRef(null)
    
    useEffect(()=>{

        const callback = ()=>{
            [overviewRef.current  , vitRef.current , contactRef.current].map((ref)=>{
                const rect = ref.getBoundingClientRect()
                if((100*rect.y/window.innerHeight)<30 && (100*rect.y/window.innerHeight)>0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll' , callback)
        return ()=>{window.removeEventListener('scroll', callback)}
    },[])
    

    return(
        <>
        <div className="singularevent-outer-wrapper">
            <CursorAnimation/>
            <div className="singularevent-title">
                <div className='go-left-arrow'>
                  <Link to="/exhibitions"><img src={ArrowLeft}/></Link>
                </div>
                Project Showcase
                <div className='go-left-arrow'>
                  
                </div>
            </div>
            <div className="singularevent-inner-wrapper">
                <div className="singularevent-navbar">
                    <ul style={{display:'flex' , listStyle:'none',backgroundColor:'#F3FF63', justifyContent:'space-evenly'}}>
                        <li onClick={()=>{setCurrent('singularevent-overview')}}>
                            <a style={current==='singularevent-overview'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Overview</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-vit')}}>
                            <a style={current==='singularevent-vit'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Virtual Industry Tour</a>
                        </li>

                        <li onClick={()=>{setCurrent('singularevent-contact')}}>
                            <a style={current==='singularevent-contact'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#singularevent-contact'>Contact</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="singularevent-content-outer">
                    <div className="singularevent-content-border clip-bg" style={{backgroundColor:'black',padding:'0px 4px 4px 4px'}}>
                        <div className="singularevent-content-inner clip-bg">
                            <div ref={overviewRef} id="singularevent-overview">
                               <div className='singularevent-img-robo-wrapper'>
                                  <SmallImage title="Robowars" img={RobotImg} />
                               </div>
                               <div className="singularevent-content-wrapper" style={{margin:window.innerWidth>'650'?'5vh 3vw 3vh 3vw':''}}>
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Overview
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        Project Showcase by Techniche, IIT Guwahati provides a stage for tech geeks to demonstrate their robotics inventions to everyone. It is an interactive session with students and innovators, to build the curiosity for tech among all.Participants get the opportunity to showcase their cutting-edge projects, receive feedback from experts, and engage in knowledge-sharing with like-minded enthusiasts.
                                    </div>  
                                      
                                </div>    
                            </div>
                            <div ref={vitRef} id="singularevent-vit">
                               {/* <div className='singularevent-img-wrapper'>
                                  <div className='clip-class' style={{height:'200px',padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={RobotImg} style={{height:'200px'}} alt=""/>
                                  </div>
                               </div> */}
                                <div className="singularevent-content-wrapper">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Virtual Industry Tour
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        Project Showcase by Techniche, IIT Guwahati provides a stage for tech geeks to demonstrate their robotics inventions to everyone. It is an interactive session with students and innovators, to build the curiosity for tech among all.Participants get the opportunity to showcase their cutting-edge projects, receive feedback from experts, and engage in knowledge-sharing with like-minded enthusiasts.
                                    </div> 
                                     
                                      
                                </div>    
                            </div>
                            
                            <div ref={contactRef} id="singularevent-contact">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Contact
                                </div>
                                <div className="singularevent-content-details">
                                    {contacts.map((item)=>{
                                        return(
                                            <div style={{margin:'10px'}}>
                                                <p style={{fontWeight:'500', fontSize:'1.2rem', fontFamily:"FK_Grotesk"}}>{item.name}</p>
                                                <p style={{fontWeight:'100', fontSize:'1rem', fontFamily:"FK_Grotesk"}}>{item.phone}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default ProjectShowcase