
const contacts = [
    {name:'Rajat Gupta',phone:'+91 9810557546'},
    {name:'Anushka Verma',phone:'+91 8529819369 '},
    {name:'Jitesh Reddy',phone:'+91 9030883199'},
]
const schedule  = [
    {item:1 , date:'Day 1',list:['Induction and Interactive Session', 'Networking Lunch', 'Hackathon (Session-I)']},
    {item:2 , date:'Day 2',list:['Keynote', 'Speed Mentoring', 'Hackathon (Session-II)']},
    {item:3 , date:'Day 3',list:['Workshop', 'Closing Ceremony', 'Hackathon (Session-III)']}
]

export {contacts , schedule}