import React from "react";
import { useNavigate } from "react-router-dom";
import "./newnavbar.css";
import TechnicheText from "../GhmAssets/Techniche-text.png"; // Update path if needed

function NewNavbar() {
  const navigate = useNavigate();

  return (
    <header className="navbar-container">
      {/* Desktop View */}
      <div className="navbar-content desktop-view">
        <button className="nav-back-btn" onClick={() => navigate(-1)}>
          ← Back
        </button>
        <img
          src={TechnicheText}
          alt="Techniche Logo"
          className="nav-logo-img"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>

      {/* Mobile View */}
      <div className="navbar-content mobile-view">
        <button className="nav-back-btn" onClick={() => navigate(-1)}>
          ← Back
        </button>
        <img
          src={TechnicheText}
          alt="Techniche Logo"
          className="nav-logo-img"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
      </div>
    </header>
  );
}

export default NewNavbar;
