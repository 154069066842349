import { useState, useEffect } from "react";
import runner1 from "../GhmAssets/runner1.png";
import runner2 from "../GhmAssets/runner2.png";
import runner3 from "../GhmAssets/runner3.png";
import runner4 from "../GhmAssets/runner4.png";
import runner5 from "../GhmAssets/runner5.png";
import curve from "../../../../components/assets/gallery.svg";

// const images = [runner1, runner2, runner3, runner4, runner5];
const IMAGES_PER_VIEW = 3; // Number of visible images

export default function Gallery() {
  const [index, setIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const images = [
    {
      img: runner1,
      siz: 110 + 230,
    },
    {
      img: runner2,
      siz: 50 + 230,
    },
    {
      img: runner3,
      siz: 30 + 230,
    },
    {
      img: runner4,
      siz: 100 + 230,
    },
    {
      img: runner5,
      siz: 180 + 230,
    },
  ];

  const nextSlide = () => {
    if (isAnimating) return; // Prevent spam clicking
    setIsAnimating(true);

    setTimeout(() => {
      setIndex((prev) => (prev + 1) % images.length); // Circular increment
      setIsAnimating(false);
    }, 500); // Match transition duration
  };

  const prevSlide = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    setTimeout(() => {
      setIndex((prev) => (prev - 1 + images.length) % images.length); // Circular decrement
      setIsAnimating(false);
    }, 500);
  };

  return (
    <div
      className="w-full lg:px-28 relative px-8 md:px-20 flex flex-col items-center overflow-hidden bg-[#fff6e4] pb-20 pt-10"
      id="galleryghm"
    >
      <div className="flex justify-between w-full py-5 my-5">
        <div className="p-3">
          <h2 className="text-[#0e1f48] font-light lg:text-3xl text-xl italic">
            Our Legacy
          </h2>
          <h1 className="text-[#0e1f48] lg:text-5xl text-3xl ">GHM OVER THE YEARS</h1>
        </div>

        <div className="flex items-center p-5 gap-2 mt-20">
          <button
            onClick={prevSlide}
            className="text-[#0e1f48] rounded-sm h-10 w-10 border-2 bg-black/0 hover:bg-black/20 transition-all"
          >
            ←
          </button>
          <button
            onClick={nextSlide}
            className="text-[#0e1f48] rounded-sm h-10 w-10 border-2 bg-black/0 hover:bg-black/20 transition-all"
          >
            →
          </button>
        </div>
      </div>
      <img
        src={curve}
        alt=""
        className="w-full absolute top-0 right-0 lg:max-w-[600px] max-w-[280px] sm:max-w-[350px] md:max-w-[400px] "
      />

      <div className="relative w-full h-[55vh] overflow-hidden">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{ transform: `translateX(-${index * 320}px)` }} // Adjust width for smooth scrolling
        >
          {images.concat(images).map(
            (
              img,
              ind 
            ) => (
              <img
                key={ind}
                src={img.img}
                className="object-cover mx-2 transition-opacity duration-500 ease-in-out"
                style={{ width: img.siz, height: img.siz + 70 }}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
