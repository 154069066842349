import React from "react";
import "./21confirm.css";
import Tick from "../GhmAssets/confirm-tick.png";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

function Confirm() {
  const { ciphertext } = useParams();

  const bytes = CryptoJS.AES.decrypt(ciphertext, "iitgtechniche");
  //const decryptedID = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedID = ciphertext.slice(-5);

  useEffect(() => {
    const postData = async () => {
      try {
        const response = await axios.post(baseURL + "/ghm/ghmpayment", {
          Decrypted_id: decryptedID,
          paymentStatus: "success",
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postData();
  }, [decryptedID]);

  return (
    <div className="container-confirm">
      <div>
        <img src={Tick} alt="=="></img>
      </div>
      <h1>your payment is confirmed</h1>
      <p>Please check your mail to get your GHM RUNNER'S ID </p>
    </div>
  );
}
export default Confirm;
