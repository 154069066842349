import { useState } from "react"

const SocialIcon = ({icon})=>{
    const [ishover , setIsHover]=useState(false)
    return(
        <a className='social-icon' href={icon.url}>
            <img src={ishover?icon.hover:icon.normal} alt="" 
            onMouseEnter={()=>{setIsHover(true)}} onMouseLeave={()=>{setIsHover(false)}}/>
        </a>
    )
}
export default SocialIcon