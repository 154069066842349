import PragatiImg from "../../../../assets/pragati2.jpg"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from "../../../../Footer/Footer";

const Pragati = ()=>{

    const [current , setCurrent ] = useState(0)
    const overviewRef = useRef(null)
    
    useEffect(()=>{

        const callback = ()=>{
            [overviewRef.current ].map((ref)=>{
                const rect = ref.getBoundingClientRect()
                if((100*rect.y/window.innerHeight)<30 && (100*rect.y/window.innerHeight)>0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll' , callback)
        return ()=>{window.removeEventListener('scroll', callback)}
    },[])
    

    return(
        <>
        <div className="singularevent-outer-wrapper">
            <div className="singularevent-title">
                <div className='go-left-arrow'>
                  <Link to="/initiatives"><img src={ArrowLeft}/></Link>
                </div>
                Pragati
                <div className='go-left-arrow'>
                  
                </div>
            </div>
            <div className="singularevent-inner-wrapper">
                <div className="singularevent-navbar">
                    <ul style={{display:'flex' , listStyle:'none',backgroundColor:'#F3FF63', justifyContent:'space-evenly'}}>
                        <li onClick={()=>{setCurrent('singularevent-overview')}}>
                            <a style={current==='singularevent-overview'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Overview</a>
                        </li>
                      
                    </ul>
                </div>
                <div className="singularevent-content-outer">
                    <div className="singularevent-content-border clip-bg" style={{backgroundColor:'black',padding:'0px 4px 4px 4px'}}>
                        <div className="singularevent-content-inner clip-bg">
                            <div ref={overviewRef} id="singularevent-overview">
                               {/* <div className='singularevent-img-wrapper'>
                                  <div className='clip-class' style={{height:'200px',padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={RobotImg} style={{height:'200px'}} alt=""/>
                                  </div>
                               </div> */}
                                <div className="singularevent2-content-wrapper">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" "/>Overview
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        This initiative, started during Techniche 2015, aims to drive transformational change in rural Assam by adopting neighbouring villages. Inspired by the Government of India's call, IIT Guwahati students focus on improving infrastructure, education, and technological awareness to create smart villages
                                    </div>  
                                      
                                </div>    
                            </div>
                            <div className='singularevent2-img-wrapper'>
                                  <div className='clip-class' style={{height:'200px',padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={PragatiImg} style={{height:'200px'}} alt=""/>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Pragati