import React,{useEffect , useState} from 'react';
import './Competitions.css';
import { Link } from 'react-router-dom';
import SmallTile from '../SmallTile/SmallTile';
import LongTile from '../LongLearnMoreTile/LongLearnMoreTile';
import VerticalTile from '../VerticalTile/VerticalTile';
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import RobowarsImg from '../../../assets/robowars.png';
import AquawarsImg from '../../../assets/aquawars.png';
import EscaladeImg from '../../../assets/escalade.png';
import MicroFishImg from '../../../assets/microfish.png';
import UVDCImg from '../../../assets/uvdc.png';
import CatalysisT from '../../../assets/catalysis.png';
import TrackTitansImg from '../../../assets/tracktitans.png';
import BridgeBuildingImg from '../../../assets/bridgebuilding.png';
import CaptureTheFlagImg from '../../../assets/capturetheflag.png';
import CircuitSimulationImg from '../../../assets/circuitsim.png';
import CodingHackathonImg from '../../../assets/codinghack.png';
import DesignCompetitionImg from '../../../assets/designcomp.png';
import GliderCompetitionImg from '../../../assets/glidercompetition.png';
import SmartCityImg from '../../../assets/smartcityhack.png';
import AvinyaImg from '../../../assets/avinya.png';
import TechExpoImg from '../../../assets/techexpo.png';
import BGMIImg from '../../../assets/bgmi.png';
import ValoImg from '../../../assets/valorant.png';
import ChessImg from '../../../assets/chess.png';
import RocketImg from '../../../assets/rocketl.png';
import TechnothlonImg from '../../../assets/technothlon.png';
import FullstackImg from '../../../assets/Fullstack.png';
import PromptEngImg from '../../../assets/PromptEng.png';
import CyberSeqImg from '../../../assets/CyberSeq.png';
import Footer from '../../../Footer/Footer';
import Marquee from '../Marquee/Marquee';
import CursorAnimation from '../../../CursorAnimation';

const Competitions = () => {
    const RoboticsItems = ['Robotics', 'Robotics', 'Robotics', 'Robotics','Robotics', 'Robotics', 'Robotics', 'Robotics',' Robotics', 'Robotics', 'Robotics', 'Robotics','Robotics', 'Robotics', 'Robotics', 'Robotics','Robotics', 'Robotics', 'Robotics', 'Robotics','Robotics', 'Robotics', 'Robotics', 'Robotics',' Robotics', 'Robotics', 'Robotics', 'Robotics','Robotics', 'Robotics', 'Robotics', 'Robotics'];
    const TecholympicsItems = ['Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics','Techolympics', 'Techolympics', 'Techolympics', 'Techolympics'];
    const CorporateItems = ['Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate','Corporate', 'Corporate', 'Corporate', 'Corporate'];
    const FunnicheItems = ['Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche','Funniche', 'Funniche', 'Funniche', 'Funniche'];
    const TechExpoItems = ['TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo','TechExpo', 'TechExpo', 'TechExpo', 'TechExpo'];
    const TechnothlonItems = ['Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon','Technothlon', 'Technothlon', 'Technothlon', 'Technothlon'];
    return ( 
        <div className="Wrapper-Workshops" >
            <CursorAnimation />
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden', paddingTop: '1vw', paddingBottom: '1vw', flexWrap:'wrap', zIndex:'95'}}>
   
               
                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/initiatives"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Initiatives</Link>
                </div>
                <Link to={`/initiatives`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>

                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Competitions</i>
                </div>

                <Link to={`/workshops`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/workshops" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Workshops</Link>
                </div>

            </div>
            {/* Event Pages Switching Navbar */}

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={RoboticsItems}/>
            <div className='robotics-container'>
               <SmallTile title="Robowars" img={RobowarsImg} color="white" link="robotics/robowars" text="Learn More" />
               <SmallTile title="MicroFish" img={MicroFishImg} color="white" link="robotics/microfish" text="Learn More" />
               <SmallTile title="Aquawars" img={AquawarsImg} color="white" link="robotics/aquawars" text="Learn More" />
               <SmallTile title="TrackTitans" img={TrackTitansImg} color="white" link="robotics/tracktitans" text="Learn More" />
               <SmallTile title="Escalade" img={EscaladeImg} color="white" link="robotics/escalade" text="Learn More" />
               <SmallTile title="UVDC" img={UVDCImg} color="white" link="robotics/uvdc" text="Learn More" />
            </div>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={TecholympicsItems}/>
            <div className='robotics-container'>
               <SmallTile title="Glider Competition" img={GliderCompetitionImg} color="white" link="techolympics/glidercompetition" text="Learn More" />
               {/* <SmallTile title="Coding Hackathon" img={CodingHackathonImg} color="white" link="techolympics/codinghackathon" text="Learn More" /> */}
               {/* <SmallTile title="Capture the flag" img={CaptureTheFlagImg} color="white" link="techolympics/capturetheflag" text="Learn More" /> */}
               <SmallTile title="SmartCity Hackathon" img={SmartCityImg} color="white" link="techolympics/smartcityhackathon" text="Learn More" />
               <SmallTile title="Circuit Simulation" img={CircuitSimulationImg} color="white" link="techolympics/circuitsimulation" text="Learn More" />
            </div>

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={CorporateItems}/>
            <div className='robotics-container'>
               <SmallTile title="Avinya" img={AvinyaImg} color="white" link="corporate/avinya" text="Learn More" />
               <SmallTile title="Catalysis'T" img={CatalysisT} color="white" link="corporate/catalysist" text="Learn More" />
            </div>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={TechExpoItems}/>
            <LongTile title="Tech Expo" img={TechExpoImg} color='white' link="techexpo" text="Learn More" />

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={FunnicheItems}/>
            <div className='robotics-container'>
               <Link to="https://unstop.com/competitions/funniche-week-bgmi-championship-techniche-iit-guwahati-1015492"><VerticalTile title="BGMI" img={BGMIImg} color="white" link="https://unstop.com/competitions/funniche-week-bgmi-championship-techniche-iit-guwahati-1015492" text="Learn More" /></Link>
               <Link to="https://unstop.com/events/funniche-week-valorant-championship-techniche-iit-guwahati-1017242"><VerticalTile title="Valorant" img={ValoImg} color="white" link="https://unstop.com/events/funniche-week-valorant-championship-techniche-iit-guwahati-1017242" text="Learn More" /></Link>
               <Link to="https://unstop.com/competitions/funniche-week-chess-championship-techniche-iit-guwahati-1022530"><VerticalTile title="Chess" img={ChessImg} color="white" link="https://unstop.com/competitions/funniche-week-chess-championship-techniche-iit-guwahati-1022530" text="Learn More" /></Link>
               <Link to="https://unstop.com/events/funniche-week-rocket-league-championship-techniche-iit-guwahati-1020226"><VerticalTile title="Rocket League" img={RocketImg} color="white" link="https://unstop.com/events/funniche-week-rocket-league-championship-techniche-iit-guwahati-1020226" text="Learn More" /></Link>
            </div>

            <Marquee bgColor="#2D48D5" letterColor="#F7F8FF" text={TechnothlonItems}/>
            <LongTile title="Technothlon" img={TechnothlonImg} text="Explore" link='https://technothlon.techniche.org.in/' color="#F7F8FF"/>

            {/* footer*/}

            <div className="footer">
                <Footer />
            </div>

        </div>
        
     );
};

 
export default Competitions;