import React, { useEffect, useState } from "react";
import "./CATasksPr.css";
import axios from 'axios'
import CAHeaderLogin from "../../CA-HeaderLogin/ca-headerlogin";

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CAShowTasks = () => {
    const [taskData, setTaskData] = useState({
        task: '',
        points: '',
        descriptions: '',
        dateOfSub: '',
    });

    const handleChange = (e) => {
        setTaskData({ ...taskData, [e.target.name]: e.target.value });
    };

    const [tasks, setTasks] = useState([]);

    useEffect(() => {
        fetchTasks();
    }, []);

    const fetchTasks = async () => {
        try {
            const response = await axios.get(baseURL + '/catasksupload/retrieveTasks');
            setTasks(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    return (

        <div>
            <div className="message-from-wo">
                <p><b>Message from Web Operations:</b> We didn't make a phone view for this page. Use your laptops for the admin panel.</p>
                <p><b>Message from Web Operations:</b> Refrain from sharing the link to this page outside Public Relations Core Team Techniche</p>
            </div>
            <div className="main1">
                <div className="right-half">
                    <div className="table1">
                        <table>
                            <thead>
                                <tr>
                                    <th className="cell-font">Name of the task</th>
                                    <th className="cell-font">Points</th>
                                    <th className="cell-font">Description</th>
                                    <th className="cell-font">Date of Submission</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tasks.map((task, index) => (
                                    <tr key={index}>
                                        <td className="cell-font">{task.task}</td>
                                        <td className="cell-font">{task.points}</td>
                                        <td className="cell-font">{task.descriptions}</td>
                                        <td className="cell-font">{task.dateOfSub}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CAShowTasks;

