import React,{useEffect , useState} from 'react';
import { Link } from 'react-router-dom';
import Tile from '../LongLearnMoreTile/LongLearnMoreTile';
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import GHMImg from '../../../assets/ghm.png';
import AutoexpoImg from '../../../assets/autoexpo.png';
import PragatiImg from '../../../assets/pragati.png';
import MuskaanImg from '../../../assets/muskaan.png';
import WUVImg from '../../../assets/wuv.png';
import Footer from '../../../Footer/Footer';
import Marquee from '../Marquee/Marquee';
import CursorAnimation from '../../../CursorAnimation';

const Initiatives = () => {
    const InitiativesItems = ['Initiatives', 'Initiatives', 'Initiatives', 'Initiatives','Initiatives', 'Initiatives', 'Initiatives', 'Initiatives',' Initiatives', 'Initiatives', 'Initiatives', 'Initiatives','Initiatives', 'Initiatives', 'Initiatives', 'Initiatives','Initiatives', 'Initiatives', 'Initiatives', 'Initiatives','Initiatives', 'Initiatives', 'Initiatives', 'Initiatives',' Initiatives', 'Initiatives', 'Initiatives', 'Initiatives','Initiatives', 'Initiatives', 'Initiatives', 'Initiatives']
    return ( 
        <div className="Wrapper-Workshops">
             <CursorAnimation />
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden', paddingTop: '1vw', paddingBottom: '1vw', flexWrap:'wrap'}}>  
                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/lectureseries"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Lecture Series</Link>
                </div>
                <Link to={`/lectureseries`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>

                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Initiatives</i>
                </div>

                <Link to={`/competitions`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/competitions" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Competitions</Link>
                </div>

            </div>
            {/* Event Pages Switching Navbar */}

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={InitiativesItems}/>
            <Tile title="GHM" img={GHMImg} color="white" link="ghm" text="Explore"/>
            <Tile title="Muskaan" img={MuskaanImg} color="white" link="initiatives/muskaan" text="Explore"/>
            <Tile title="Pragati" img={PragatiImg} color="white" link="initiatives/pragati" text="Explore"/>
            <Tile title="Wake Up And Vote" img={WUVImg} color="white" link="initiatives/wakeupandvote" text="Explore"/>


            {/* footer*/}

            <div className="footer">
                <Footer />
            </div>

        </div>
        
     );
};

 
export default Initiatives;