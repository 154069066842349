import React,{useEffect , useState} from 'react';
import { Link } from 'react-router-dom';
import Tile from '../LongLearnMoreTile/LongLearnMoreTile';
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import AutoexpoImg from '../../../assets/autoexpo.png';
import ProjectImg from '../../../assets/projectshowcase.png';
import CyberSeqImg from '../../../assets/CyberSeq.png';
import Footer from '../../../Footer/Footer';
import Marquee from '../Marquee/Marquee';
import CursorAnimation from '../../../CursorAnimation';

const Exhibitions = () => {
    const ExhibitionItems = ['Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions','Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions',' Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions','Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions','Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions','Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions',' Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions','Exhibitions', 'Exhibitions', 'Exhibitions', 'Exhibitions']
    return ( 
        <div className="Wrapper-Workshops">
            <CursorAnimation/>
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden',paddingTop: '1vw', paddingBottom: '1vw', flexWrap:'wrap', zIndex:'95'}}>

                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/workshops"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Workshops</Link>
                </div>
                <Link to={`/workshops`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>

                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Exhibitions</i>
                </div>

                <Link to={`/lectureseries`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/lectureseries" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Lecture Series</Link>
                </div>

            </div>
            {/* Event Pages Switching Navbar */}

            <Marquee bgColor="#F3FF63" letterColor="#2D48D5" text={ExhibitionItems}/>
            <Tile title="Project Showcase" img={ProjectImg} color="white" link="exhibitions/projectshowcase" text="Explore"/>
            <Tile title="Autoexpo and Roadshow" img={AutoexpoImg} color="white" link="exhibitions/autoexpo" text="Explore"/>


            {/* footer*/}

            <div className="footer">
                <Footer />
            </div>

        </div>
        
     );
};

 
export default Exhibitions;