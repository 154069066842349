import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import six from '../../../../components/assets/six.svg'
import twentyone from '../../../../components/assets/twentyone-km.svg'
import card from '../GhmAssets/Card.svg'
import curve from '../../../../components/assets/curve.svg'
gsap.registerPlugin(ScrollTrigger);

const Distance = () => {
  const cardRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      cardRef.current,
      { x: 300, opacity: 0 },
      {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: "power2.out",
        scrollTrigger: {
          trigger: cardRef.current,
          start: "top 80%",
          toggleActions: "play none none reverse",
        },
      }
    );
  }, []);

  return (
    <div>
      <div className="bg-[#F7E8C9] py-0 flex flex-col justify-center"> 
        <div className="flex  justify-between items-start">
          <div className="px-8 md:pl-16 lg:pl-36 w-full md:w-4/6 py-4 md:py-8">
            <span className="text-blue-950 font-heading text-4xl md:text-5xl font-normal leading-[1.2]">
              <i className="text-blue-950 font-light">Every Step Counts</i> <br />
            </span>
            <span className="text-blue-950 font-heading text-4xl md:text-5xl font-bold uppercase leading-[1.2]">
              CHHOSE YOUR CHALLENGE
            </span>
          </div>
          <div className=" flex justify-end">
            <img src={curve} alt="" className="w-full lg:max-w-[600px] max-w-[280px] sm:max-w-[350px] md:max-w-[400px] " />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-around pt-10 items-center px-4 md:px-0">
          <img src={six} alt="Six KM" className="w-2/3 md:w-auto max-w-[290px] mb-4 md:mb-0" />
          <img src={twentyone} alt="Twenty One KM" className="w-2/3 md:w-auto max-w-[305px] mb-4 md:mb-0" />
          <img
            ref={cardRef}
            src={card}
            alt="Card"
            className="opacity-0 pb-4 w-2/3 md:w-auto max-w-[290px] mb-4"
          />
        </div>
      </div>
    </div>
  );
};

export default Distance;