import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import "./header.css";
import logo from "../assets/header-logo.png";
import { useAuth } from '../../services/authContext';
import { ReactComponent as CloseIcon } from '../assets/cross.svg';
import { ReactComponent as MenuIcon } from '../assets/burger-menu.svg';




function Header() {
  const [showMenu, setShowMenu] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const { isLoggedInMain, userDataMain, logoutmain } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };


  const handleLogout = () => {
    logoutmain();
  };


  const handleLinkClick = (path) => {
    setShowMenu(false);
    navigate(path);
  };


  const handleLogoClick = () => {
    setShowMenu(false);
    navigate('/');
  };


  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY <= 100) {
        setShowHeader(true); // Always show header in the first 100vh
      } else if (currentScrollY > lastScrollY) {
        setShowHeader(false); // Scrolling down
      } else {
        setShowHeader(true); // Scrolling up
      }
      setLastScrollY(currentScrollY);
    };


    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);


  const getNavItemClass = (path) => {
    return location.pathname === path ? 'highlighted-header' : '';
  };


  const hamburger = () => {
    return (
      <div className='hamburger-wrapper'>
        <ul>
          <li className={getNavItemClass('/events')} onClick={() => handleLinkClick('/events')}>Events</li>
          <li className={getNavItemClass('/ca')} onClick={() => handleLinkClick('/ca')}>Campus Ambassador</li>
          {/* <li className={getNavItemClass('/merch')} onClick={() => handleLinkClick('/merch')}>Merch</li> */}
          <li className={getNavItemClass('/about')} onClick={() => handleLinkClick('/about')}>About</li>
          <li className={getNavItemClass('/sponsors')} onClick={() => handleLinkClick('/sponsors')}>Sponsors</li>
          {isLoggedInMain && (
            <li className={getNavItemClass(`/dashboard/${userDataMain.sanitizedHash}`)} onClick={() => handleLinkClick(`/dashboard/${userDataMain.sanitizedHash}`)}>Dashboard</li>
          )}
          <li className={getNavItemClass('/signup')} onClick={() => handleLinkClick('/signup')}>Register</li>
        </ul>
      </div>
    );
  };


  return (
    <div
      className={`header-wrapper-outter ${showHeader ? 'visible' : 'hidden'}`}
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '120',
        width: '100vw',
        transition: 'top 0.3s ease-in-out'
      }}
    >
      {showMenu && hamburger()}
      <div
        className='header-wrapper'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '1vh 10%',
          transition: 'opacity 0.3s ease-in-out'
        }}
      >
        <div className='header-logo-wrapper' style={{ display: 'flex', width: '20%', alignItems: 'center', fontSize: '1.1rem', fontWeight: '100' }}>
          <Link to='/' onClick={handleLogoClick}>
            <img src={logo} className='header-logo' alt='' style={{ width: "60%", margin: '0px 10px' }} />
          </Link>
        </div>
        <div className='header-navbar-wrapper' style={{ alignItems: 'center' }}>
          <ul className={`header-navbar-ul ${showMenu ? 'show' : ''}`} style={{ listStyle: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem' }}>
            <Link to="/events" onClick={() => setShowMenu(false)}><li className={`events-header-title ${getNavItemClass('/events')}`}>Events</li></Link>
            <Link to="/ca" onClick={() => setShowMenu(false)}><li className={`ca-header-title ${getNavItemClass('/ca')}`}>Campus Ambassador</li></Link>
            {/* <Link to="/merch" onClick={() => setShowMenu(false)}><li className={`merch-header-title ${getNavItemClass('/merch')}`}>Merch</li></Link> */}
            <Link to="/about" onClick={() => setShowMenu(false)}><li className={`about-header-title ${getNavItemClass('/about')}`}>About</li></Link>
            <Link to="/sponsors" onClick={() => setShowMenu(false)}><li className={`about-header-title ${getNavItemClass('/sponsors')}`}>Sponsors</li></Link>
            {isLoggedInMain && (
              <Link to={`/dashboard/${userDataMain.sanitizedHash}`} onClick={() => setShowMenu(false)}><li id="dashboard" className={`dashboard-header-title ${getNavItemClass(`/dashboard/${userDataMain.sanitizedHash}`)}`}>Dashboard</li></Link>
            )}
          </ul>
        </div>
        <div className='header-btn-wrapper'>
          {isLoggedInMain ? (
            <Link to="/login" style={{ height: '12vh' }}>
              <div className='merch-btn-wrapper' style={{ backgroundColor: 'black', width: 'max-content', padding: '0.05vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }} onClick={handleLogout}>
                <span className='merch-btn'>Logout</span>
              </div>
            </Link>
          ) : (
            <Link to="/signup" style={{ height: '12vh' }}>
              <div className='merch-btn-wrapper' style={{ backgroundColor: 'black', width: 'max-content', padding: '0.05vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                <span className='merch-btn'>Sign Up</span>
              </div>
            </Link >
          )}
        </div>
        <div className='hamburger-icon-wrapper' onClick={handleToggleMenu}>{showMenu ? <CloseIcon /> : <MenuIcon />}</div>
      </div>
    </div>
  );
}


export default Header;