import React from "react";
import { Link } from "react-router-dom";
import './VerticalTile.css';
import { CIcon } from '@coreui/icons-react';
import { cilExternalLink} from '@coreui/icons';

const Tile = (props) => {
    return (
        <div className="VerticalTileWrapper">
            <div className="VerticalTileContainer">
                <Link to={`${props.link}`}>
                <img className="VerticalTileImage" src={props.img} alt="Blockchain" />
                <p style={{ color: props.color }}>{props.title}</p>
                <div className="LearnMoreWrapper" style={{marginTop:'1vw'}}>
                    <div className="LearnMoreContainer">
                        {props.text}
                    </div>
                    <CIcon className="LearnMoreicon" icon={cilExternalLink} />
                </div>
                </Link>
            </div>
        </div>
    );
}

export default Tile;
