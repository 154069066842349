import React, { useState } from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import TechnicheText from "../GhmAssets/Techniche-text.png";
import Hamburger from "../GhmAssets/Hamburger.png";
import TText from "../GhmAssets/frr.png";

function Navbar() {
  // State to toggle mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate function

  // Toggles the mobile menu (three links)
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className="navbar-container fixed">
      <div className="navbar-content desktop-view">
        <div className="nav-left">
        <a href="/"><img
          src={TechnicheText}
          alt="Techniche Logo"
          className="nav-logo-img"
          onClick={() => navigate("/")} 
          style={{ cursor: "pointer" }}
        /></a>
      </div>
        <div className="nav-center">
        <button className="nav-link" onClick={() => scrollToSection("about")}>
            About
          </button>
          <button className="nav-link" onClick={() => scrollToSection("merchghm")}>
            Merch
          </button>
          <button className="nav-link" onClick={() => scrollToSection("galleryghm")}>
            Gallery
          </button>
          <button className="nav-link" onClick={() => scrollToSection("footerghm")}>
            Contact
          </button>
        </div>
        <div className="nav-right">
          <button className="register-btn" onClick={() => navigate("/ghm/register")}>
            Register Now
          </button>
        </div>
      </div>

      {/* ---------- MOBILE VIEW ---------- */}
      <div className="navbar-content mobile-view">
        <div className="mobile-left">
          {/* The “logo” that toggles the menu on mobile */}
          <div className="mobile-logo" onClick={toggleMobileMenu}>
            {/* This could be the three-line (hamburger) icon OR a smaller logo */}
                <img
                src={Hamburger}
                alt="Habmburger Logo"
                className="nav-logo-img cursor-pointer"
                />
            </div>

          {/* An additional image or text to the right of the logo */}
          <a href="/"><img
            src={TText}
            alt="Techniche Text"
            className="mobile-text-img cursor-pointer"
          /></a>
        </div>

        {/* Right Section (Register Button) */}
        <div className="mobile-right">
        <button className="register-btn mb-4" onClick={() => navigate("/ghm/register")}>
            Register Now
          </button>
        </div>
      </div>

      {/* ---------- MOBILE MENU (TOGGLED) ---------- */}
      {isMobileMenuOpen && (
        <div className="mobile-dropdown">
          <a href="#about" className="dropdown-link" onClick={toggleMobileMenu}>
            About
          </a>
          <a
            href="#merchghm"
            className="dropdown-link"
            onClick={toggleMobileMenu}
          >
            Merch
          </a>
          <a
            href="#galleryghm"
            className="dropdown-link"
            onClick={toggleMobileMenu}
          >
            Gallery
          </a>
        </div>
      )}
    </header>
  );
}

export default Navbar;
