import React from "react";
import merch from "../GhmAssets/merch.png";
// import { DM_Sans } from "next/font/google";
// const dmSans = DM_Sans({ subsets: ["latin"], weight: ["400", "500", "700"] });

const Merch = () => {
    return (
        <div className="bg-[#F7E8C9] min-h-screen flex items-center justify-center px-6 py-12" id="merchghm">
            <div className="max-w-6xl w-full grid md:grid-cols-2 gap-8 items-center">
                {/* Left Section - Text */}
                <div className={`text-center space-y-16 md:text-left`}>
                    <h2 className="text-3xl  md:text-4xl xl:text-5xl font-semibold text-gray-900 leading-tight break-words">
                        <em className="text-[#0e1f48] font-thin italic">Run in Style</em>
                        <br />
                        <span className="text-[#0e1f48] font-semibold">OFFICIAL GHM MERCH</span>
                    </h2>
                    <p className="mt-4 font-light text-lg md:text-xl xl:text-2xl text-[#0e1f48] leading-relaxed">
                    As a memento to help you relive the ecstatic moment when you cross the finish line, we present to you the official merch for Guwahati Half Marathon 2025! 
                    </p>
                    <div className="mt-6 hidden md:flex items-center gap-4">
                    <button
  onClick={() => window.open("https://forms.gle/BakusxfCmhPjBVgYA", "_blank")}
  className="bg-[#0a1930] text-white px-6 py-3 font-medium shadow-md hover:bg-[#122a45]"
>
  Buy Merch
</button>

                        <span className="text-gray-800 font-semibold">Hurry - Limited Stock</span>
                    </div>
                </div>

                {/* Right Section - Images */}
                <div className="relative flex justify-center items-center overflow-hidden h-full">
    <img
        src={merch} // Replace with your PNG path
        alt="Guwahati Half Marathon Merch"
        className="max-w-[400px] md:max-w-[200px] xl:max-w-[300px] object-contain drop-shadow-lg z-10 scale-[1.35] md:scale-[1.6] xl:scale-[1.85]"
    />
</div>


                {/* Mobile Buy Button */}
                <div className="mt-6 flex md:hidden items-center gap-4">
                <button
  onClick={() => (window.location.href = "https://forms.gle/BakusxfCmhPjBVgYA")}
  className="bg-[#0e1f48] text-white px-6 py-3 rounded-md font-medium shadow-md hover:bg-[#122a45]"
>
  Buy Merch
</button>

                    <span className="text-gray-800 font-semibold">Hurry – Limited Stock!</span>
                </div>
            </div>
        </div>
    );
};

export default Merch;
