import AvinyaImg from "../../../../assets/avinya.png"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { prizes, contacts, schedule } from './data'
import { useEffect, useRef, useState } from 'react'
import Footer from "../../../../Footer/Footer"
import { Link } from 'react-router-dom';
import CursorAnimation from '../../../../CursorAnimation'
import SmallImage from "../../Small-Image/Small-Image"

const Avinya = () => {

    const [current, setCurrent] = useState(0)
    const overviewRef = useRef(null)
    const eventRef = useRef(null)
    const prizesRef = useRef(null)
    const contactRef = useRef(null)
    const registerRef = useRef(null)

    useEffect(() => {

        const callback = () => {
            [overviewRef.current, eventRef.current,
            prizesRef.current, contactRef.current, registerRef.current].map((ref) => {
                const rect = ref.getBoundingClientRect()
                if ((100 * rect.y / window.innerHeight) < 30 && (100 * rect.y / window.innerHeight) > 0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll', callback)
        return () => { window.removeEventListener('scroll', callback) }
    }, [])

    const prizeCard = (item) => {
        return (
            <div className='singularevent-prize-card-outer clip-class-prize'>
                <div className='singularevent-prize-card-inner clip-class-prize'>
                    <p style={{ color: '#2D48D5', fontWeight: '300', fontSize: window.innerWidth > 400 ? '0.9rem' : '0.7rem' }}>{item.position}</p>
                    <p style={{ color: '#2D48D5', fontWeight: '600', fontSize: window.innerWidth > 400 ? '1.4rem' : '0.9rem' }}>{item.prize}</p>
                </div>
            </div>
        )
    }



    return (
        <>
            <div className="singularevent-outer-wrapper">
                <CursorAnimation />
                <div className="singularevent-title">
                    <div className='go-left-arrow'>
                        <Link to="/competitions"><img src={ArrowLeft} /></Link>
                    </div>
                    Avinya
                    <div className='go-left-arrow'>

                    </div>
                </div>
                <div className="singularevent-inner-wrapper">
                    <div className="singularevent-navbar">
                        <ul style={{ display: 'flex', listStyle: 'none', backgroundColor: '#F3FF63', justifyContent: 'space-evenly' }}>
                            <li onClick={() => { setCurrent('singularevent-overview') }}>
                                <a style={current === 'singularevent-overview' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#'>Overview</a></li>
                            <li onClick={() => { setCurrent('singularevent-event') }}>
                                <a style={current === 'singularevent-event' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#events-scroll-tracker'>Event Details</a></li>
                            <li onClick={() => { setCurrent('singularevent-registration') }}>
                                <a style={current === 'singularevent-prizes' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#prizes-scroll-tracker'>Prizes</a></li>
                            <li onClick={() => { setCurrent('singularevent-contact') }}>
                                <a style={current === 'singularevent-contact' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Contact</a></li>
                            <li onClick={() => { setCurrent('singularevent-register') }}>
                                <a style={current === 'singularevent-register' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Register</a></li>
                        </ul>
                    </div>
                    <div className="singularevent-content-outer">
                        <div className="singularevent-content-border clip-bg" style={{ backgroundColor: 'black', padding: '0px 4px 4px 4px' }}>
                            <div className="singularevent-content-inner clip-bg">
                                <div ref={overviewRef} id="singularevent-overview">
                                    <div className='singularevent-img-robo-wrapper'>
                                        <SmallImage title="Robowars" img={AvinyaImg} />
                                    </div>
                                    <div className="singularevent-content-wrapper" style={{ margin: window.innerWidth > '650' ? '5vh 3vw 3vh 3vw' : '' }}>
                                        <div className="singularevent-content-heading">
                                            <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Overview
                                        </div>
                                        <div className="singularevent-content-details">
                                            <div id='events-scroll-tracker'></div>
                                            Prakriti Club, the premier techno-environmental club of IIT Guwahati, proudly presents Avinya - 2024, The flagship hackathon event. Designed to ignite innovation and creativity, Avinya challenges participants to develop cutting-edge solutions for real-world environmental problems based on climate change, energy transition & sustainability.
                                        </div>
                                    </div>
                                </div>
                                <div ref={eventRef} id="singularevent-event">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Event Details
                                    </div>
                                    <div className="singularevent-content-details">
                                        Round 1: Participants will dive into six diverse and challenging problem statements across various domains. Each team must choose one problem statement, craft a comprehensive solution, and submit their detailed proposal in PowerPoint format by the deadline.
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                    <div className="singularevent-content-details">
                                        Round 2: The top teams will earn the opportunity to present their innovative ideas on the prestigious IIT Guwahati campus during Techniche, our annual techno-management festival. This will be followed by an intense 24 to 30-hour live hackathon, where teams will tackle new problem statements provided by industry experts based on pre-defined domains.
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                </div>
                                <div ref={prizesRef} id="singularevent-prizes" style={{ marginBottom: '5vh' }}>
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Prize Pool Worth Rs. 1,00,000/-
                                    </div>
                                </div>
                                <div id="singularevent-schedule">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Schedule
                                    </div>
                                    <div className="singularevent-content-details">
                                        {schedule.map((item) => {
                                            return (
                                                <div key={item.id} style={{ margin: '10px', fontFamily: "FK_Grotesk" }}>
                                                    <div style={{ fontSize: '1.2rem', color: '#F3FF63', fontWeight: '500', fontFamily: "FK_Grotesk" }}>{item.date}{item.id < 3 && <hr />}</div>
                                                    <ul className="schedule-day-date" style={{ fontSize: '100', fontSize: '1rem', letterSpacing: '0.03rem' }}>
                                                        <li>{item.list[0]}</li>
                                                        <li>{item.list[1]}</li>
                                                        <li>{item.list[2]}</li>
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={contactRef} id="singularevent-contact">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Contact
                                    </div>
                                    <div className="singularevent-content-details">
                                        {contacts.map((item) => {
                                            return (
                                                <div style={{ margin: '10px' }}>
                                                    <p style={{ fontWeight: '500', fontSize: '1.2rem', fontFamily: "FK_Grotesk" }}>{item.name}</p>
                                                    <p style={{ fontWeight: '100', fontSize: '1rem', fontFamily: "FK_Grotesk" }}>{item.phone}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={registerRef} className="singularevent-register">
                                    {/* <Link to="https://unstop.com/competitions/avinya-2024-prakriti-ecoinnovate-challenge-iit-guwahati-1068985"> */}
                                    <div className='clip-btn singularevent-btn-outer' style={{ backgroundColor: 'black', width: 'max-content', padding: '2px' }}>
                                        <div className='clip-btn singularevent-btn-inner' style={{ fontFamily: "FK_Grotesk", backgroundColor: '#F3FF63', width: 'max-content', fontSize: '1rem', padding: '10px 40px' }}>Registrations Closed. Coming Soon for 2025!</div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Avinya