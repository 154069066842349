import React, { useState } from "react";
import svg1 from '../../../../components/assets/svg1.svg';
import svg2 from '../../../../components/assets/svg2.svg';
import svg3 from '../../../../components/assets/svg3.svg';
import svg4 from '../../../../components/assets/svg4.svg';
import svg5 from '../../../../components/assets/svg5.svg';
import curve from '../../../../components/assets/curve2.svg';
import rotated from '../../../../components/assets/rotated.svg';

const ThisYear2 = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);

  return (
    <div className="bg-[#F7E8C9] font-heading h-[88vh] overflow-hidden relative">
      <style>{`
        .hover-transition {
          transition: filter 0.3s ease-in-out;
        }
        .filter-blue {
          filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(700%) hue-rotate(204deg) brightness(94%) contrast(97%);
        }
        .filter-red {
          filter: brightness(0) saturate(100%) invert(30%) sepia(100%) saturate(1500%) hue-rotate(-4deg) brightness(100%) contrast(124%);
        }
        .filter-teal {
          filter: brightness(0) saturate(100%) invert(48%) sepia(13%) saturate(800%) hue-rotate(130deg) brightness(95%) contrast(80%);
        }
      `}</style>
      <div className="flex flex-col space-y-12 md:flex-row md:space-y-0 px-8 md:px-8 lg:px-0 lg:flex-row lg:space-y-0 justify-around lg:pt-28 md:pt-40 sm:pt-20 pt-32">
        <h1 className="font-medium lg:text-6xl md:text-5xl text-4xl text-[#0E1F48]">
          <span className="lg:-ml-6 -ml-4 text-[#FE80A5]">"</span>
          RUN FOR A <br />{" "}
          <span className="text-[#FE80A5] font-bold lg:text-6xl md:text-5xl text-4xl">
            HEALTHIER
          </span>{" "}
          <br /> SOCIETY<span className="text-[#FE80A5]">"</span>
        </h1>
        <p className="text-[#0E1F48] text-xl max-w-md font-light">
        Every year, GHM runners come together for a positive change in society. The problem we choose to tackle is based on the current scenario, with the UN Sustainable Development Goals as our guiding light. <br />
        Join us this year as we run for a Healthier Society. Together, let’s give back to the city that nurtures us all.
        </p>
        <img src={rotated} className="lg:hidden block absolute md:top-0 -top-12 right-0" alt="" />
      </div>
      <div className="flex justify-around">
        <img
          className="hidden lg:block  relative mr-auto"
          src={curve}
          alt=""
        />
        <div className="mt-12 mr-32 relative bottom-16 lg:bottom-20 md:bottom-20 sm:bottom-16 space-y-6 mx-auto lg:mx-32 md:mx-28 sm:mx-24 hidden sm:block lg:block md:block">
          <div className="flex space-x-4">
            <img
              src={svg1}
              alt=""
              className={`inline-block hover-transition ${isHovered ? "filter-blue" : ""}`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            />
            <img
              alt=""
              className={`hover-transition ${isHovered2 ? "filter-red" : ""} mt-24`}
              onMouseEnter={() => setIsHovered2(true)}
              onMouseLeave={() => setIsHovered2(false)}
              src={svg2}
            />
          </div>
          <div className="flex">
            <img
              src={svg3}
              className={`hover-transition ${isHovered3 ? "filter-teal" : ""
                } relative bottom-32 right-5`}
              onMouseEnter={() => setIsHovered3(true)}
              onMouseLeave={() => setIsHovered3(false)}
              alt=""
            />
            <img
              src={svg4}
              className={`hover-transition ${isHovered4 ? "filter-red" : ""
                } relative bottom-28 right-3`}
              onMouseEnter={() => setIsHovered4(true)}
              onMouseLeave={() => setIsHovered4(false)}
              alt=""
            />
            <img
              src={svg5}
              className={`hover-transition ${isHovered5 ? "filter-blue" : ""
                } relative bottom-28 left-24`}
              onMouseEnter={() => setIsHovered5(true)}
              onMouseLeave={() => setIsHovered5(false)}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThisYear2;