import React from "react";
import ReactDOM from "react-dom/client";
import GhmLanding from "./components/GhmLanding.js";
import Navbar from "./components/Navbar.js";
import "./GhmLanding.css";
import HeroSection from "./components/Herosection.js";
import ThisYear from "./components/thisyear.js";
import MarathonPage from "./components/MarathonPage/MarathonPage.jsx";
import GHMBenefitsAndSponsors from "./components/GHMBenefitsAndSponsors/GHMBenefitsAndSponsors.jsx";
import Footer from "./components/Footer_25/foot.js"
import Merch from "./components/Merch.js";
import Gallery from './components/Gallery.js'
import ThisYear2 from "./components/ThisYear2.js";
import Distance from "./components/Distance.js";


function GhmMain() {
  return (
    <div className="ghm-container">
      <Navbar />
      <HeroSection />
      <MarathonPage />
      <ThisYear2 />
      <ThisYear />
      <Merch />
      <Gallery/>
      <Distance />
      <GHMBenefitsAndSponsors/>
      <Footer />
    </div>
  );
}

export default GhmMain;
