const YearCard = ({years , current})=>{
    return(
      <div className='year-card-wrapper'>
      <span className='year-card-wrapper-2'> 
          <div className='year-card-text'>
            <span style={{color:'black', fontFamily:'FK_Grotesk', fontSize:'1.1rem'}}>{years[current].text}</span>
          </div>
          <div className='year-card-img'>
            <img src={years[current].url}/>
          </div>
      </span>
      </div>
    )}

export default YearCard