import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './ViewTeamDetailsRW.css';
import { useAuth } from '../../../../services/authContext';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const ViewTeamDetailsTT = () => {
    const { sanitizedHash } = useParams();
    const [teamData, setTeamData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDataMain } = useAuth();

    useEffect(() => {
        console.log('userDataMain:', userDataMain);
        const fetchTeamData = async () => {
            try {
                const response = await axios.post(`${baseURL}/tracktitans/viewteam`, {
                    email: userDataMain.email,
                    t_id: userDataMain.t_id,
                });
                setTeamData(response.data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchTeamData();
    }, [userDataMain.email]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <div className="viewteam-rw-container">
            <div className="team-member">
                <label>Team Leader:</label>
                <p>Name: {teamData.teamLeader?.name}</p>
                <p>Email: {teamData.teamLeader?.email}</p>
                <p>T_ID: {teamData.teamLeader?.t_id}</p>
            </div>
            <div className="team-member">
                <label>Team Member 1:</label>
                <p>Name: {teamData.members.member1?.name}</p>
                <p>Email: {teamData.members.member1?.email}</p>
                <p>T_ID: {teamData.members.member1?.t_id}</p>
            </div>
            <div className="team-member">
                <label>Team Member 2:</label>
                <p>Name: {teamData.members.member2?.name}</p>
                <p>Email: {teamData.members.member2?.email}</p>
                <p>T_ID: {teamData.members.member2?.t_id}</p>
            </div>
            <div className="team-member">
                <label>Team Member 3:</label>
                <p>Name: {teamData.members.member3?.name}</p>
                <p>Email: {teamData.members.member3?.email}</p>
                <p>T_ID: {teamData.members.member3?.t_id}</p>
            </div>
            <div className="team-member">
                <label>Team Member 4:</label>
                <p>Name: {teamData.members.member4?.name}</p>
                <p>Email: {teamData.members.member4?.email}</p>
                <p>T_ID: {teamData.members.member4?.t_id}</p>
            </div>
        </div>
    );
};

export default ViewTeamDetailsTT;
