import React, { useState } from "react";
import "./CAExtraPr.css";
import axios from 'axios';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CAExtraPoints = () => {
    const [t_id, setTID] = useState('');
    const [points, setPoints] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const regex = /^T24(\d{5})$/;
            if (!regex.test(t_id)) {
                alert("Wrong Format of T_ID");
                return;
            }

            const userId = parseInt(t_id.substring(3)) - 10000;
            if (userId < 0) {
                alert("Wrong Format of T_ID");
                return;
            }

            if (!points) {
                alert("Please enter points");
                return;
            }

            await axios.put(baseURL + '/catasksupload/extraPointsTID', {
                userId: userId,
                points: points
            });

            alert("Extra Points added!!");
            setTID('');
            setPoints('');
        } catch (error) {
            console.log(error);
            alert("Error occurred while adding extra points :-(");
        }
    }

    return (
        <div>
            <div className="message-from-wo">
                <p><b>Message from Web Operations:</b> We didn't make a phone view for this page. Use your laptops for the admin panel.</p>
                <p><b>Message from Web Operations:</b> Refrain from sharing the link to this page outside Public Relations Core Team Techniche</p>
            </div>
            <div className="main1">
                <div className="left-half">
                    <div className="signup-form ml-5 mr-5 mb-10 lg:mt-24 lg:mb-10">
                        <p className='heading text-4xl'>Enter T_ID of CA for Extra Points</p>
                        <form onSubmit={handleSubmit}>
                            <input type="text" name="t_id" placeholder="t_id" value={t_id} onChange={(e) => setTID(e.target.value)} required /><br />
                            <input type="text" name="points" placeholder="Points" value={points} onChange={(e) => setPoints(e.target.value)} required /><br />
                            <button type="submit" className='signup transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500'>Submit Task</button><br />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CAExtraPoints;