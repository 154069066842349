import React, { useState } from "react";
import star from "../GhmAssets/Star6.png";
import img1a from "../GhmAssets/Subtract1a.png";
import img1b from "../GhmAssets/Subtract1b.png";
import img2a from "../GhmAssets/Subtract2a.png";
import img2b from "../GhmAssets/Subtract2b.png";
import img3a from "../GhmAssets/Subtract3a.png";
import img3b from "../GhmAssets/Subtract3b.png";
import img4a from "../GhmAssets/Subtract4a.png";
import img4b from "../GhmAssets/Subtract4b.png";
import img5a from "../GhmAssets/Subtract5a.png";
import img5b from "../GhmAssets/Subtract5b.png";
import img6a from "../GhmAssets/Subtract6a.png";
import img6b from "../GhmAssets/Subtract6b.png";
import img7a from "../GhmAssets/Subtract7a.png";
import img7b from "../GhmAssets/Subtract7b.png";
import img8a from "../GhmAssets/Subtract8a.png";
import img8b from "../GhmAssets/Subtract8b.png";

const categories = [
     { title: "Communicable Disease", description: "Depicting two individuals and the interaction zone around them, this symbol talks about controlling and preventing the spread of communicable diseases for a healthy society.", icon: img1a, iconh: img1b, color: "#F34E4E" },
     { title: "Non-Communicable Disease", description: "Derived from a DNA sign and an hourglass, this symbol depicts prevention of acute or chronic non-communicable diseases, promoting a healthier lifestyle and providing care to those in need.", icon: img2a, iconh: img2b, color: "#3D77FF" },
     { title: "Workforce", description: "The symbol depicts a badge and a cog wheel, representing the responsibility and power of medical and research workforce in a society.", icon: img3a, iconh: img3b, color: "#FF6E45" },
     { title: "Red Ribbon", description: "The Red Ribbon is a global representation of health and wellness, and sums up our larger goal of contributing to a healthy world.", icon: img4a, iconh: img4b, color: "#FC4F4F" },
     { title: "Substance Abuse", description: "Depicting a pill and a NO sign, this symbol talks about tackling substance abuse in a society, for promoting mental and physical wellbeing.", icon: img5a, iconh: img5b, color: "#A730BF" },
     { title: "Pollution and Health", description: "Deriving it’s shape from a germ, this symbol creates awareness about hygiene, and the spread of ill health through air or water pollution ", icon: img6a, iconh: img6b, color: "#339985" },
     { title: "Mental Health", description: "Depicting a brain, this symbol brings attention to mental illness as a serious health concern in today’s society.", icon: img7a, iconh: img7b, color: "#3D77FF" },
     { title: "Child and Maternal", description: "Made up of concentric circles to represent a child in a womb, and also a pram, this symbol talks about the health of a mother and child post-birth.", icon: img8a, iconh: img8b, color: "#FE80A5" },
];

const ThisYear = () => {
     const [hoveredIndex, setHoveredIndex] = useState(null);

     return (
          <div className="relative min-h-screen width-max flex flex-col items-center justify-center bg-[#FFF6E4] p-0 sm:p-8">
               {/* Star Image (Positioned on the rightmost side) */}
               <img src={star} alt="Star" className="hidden sm:block absolute top-20 right-0 w-30 h-auto" />

               <div className="w-full max-w-6xl mx-auto mt-20">
                    <h1 className="text-3xl font-semibold text-left mb-10 text-[#0E1F48] z-100">
                         <div className="text-3xl font-light text-left mb-2 text-[#0E1F48] italic z-40" >In Running for a Healthier Society</div>
                         <span className="font-bold text-[#0E1F48]" style={{ fontSize: (window.innerWidth >= 640 ? "30px" : "25px") }}>WE RUN AGAINST</span>
                    </h1>
               </div>

               {/* 8-Box Grid */}
               <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 w-full max-w-6xl border border-[#D3C4A1] h-full min-h-[400px]">
                    {categories.map((item, index) => (
                         <div
                              key={index}
                              className="justify-start items-start sm: relative flex flex-col p-6 border border-[#D3C4A1] text-left transition-all duration-300 bg-[#FFF6E4] text-[#0F2155] hover:text-white"

                              style={{ backgroundColor: hoveredIndex === index ? item.color : "#FFF6E4" }}
                              onMouseEnter={() => window.innerWidth >= 640 && setHoveredIndex(index)}
                              onMouseLeave={() => window.innerWidth >= 640 && setHoveredIndex(null)}
                         >

                              <div style={window.innerWidth >= 640 ? { display: "flex", flexDirection: "column" } : { display: "flex", flexDirection: "row" }}>
                                   <div style={window.innerWidth >= 640 ? { display: "flex" } : { display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "center", flexShrink: 0, marginRight: "16px", minWidth: "120px" }}>
                                        <div className="-ml-6 -mt-4">
                                             <img
                                                  src={window.innerWidth >= 640 ? (hoveredIndex === index ? item.iconh : item.icon) : item.icon}
                                                  alt="Icon"
                                                  className="relative top-6 left-6 transition-all duration-300 object-contain"
                                                  style={window.innerWidth >= 640 ? { width: "80px", height: "auto" } : { width: "60px", height: "auto", flexShrink: 0, marginRight: "16px", marginTop: "-90px" }}
                                             />
                                        </div>

                                        {/* Title */}
                                        <div className="font-semibold mt-32 pr-1 absolute z-10 text-[#0E1F48]"

                                             style={window.innerWidth >= 640 ? { color: (hoveredIndex === index ? "#FFFCF5" : "#0E1F48"), fontSize: "24px" } : { color: "#0E1F48", fontSize: "15px", flex: 1, width: "60px", marginTop: "50px" }}
                                             onMouseEnter={() => window.innerWidth >= 640 && setHoveredIndex(index)}
                                             onMouseLeave={() => window.innerWidth >= 640 && setHoveredIndex(null)}
                                        >{item.title}</div>
                                   </div>

                                   {/* Description (Initially Hidden, Shown on Hover) */}
                                   {item.description && (
                                        <p className="sm: opacity-0 transition-all duration-300 z-10 text-base mb-0 font-light"
                                             style={{ opacity: window.innerWidth >= 640 ? (hoveredIndex === index ? 1 : 0) : 1, color: window.innerWidth >= 640 ? "#FFFCF5" : "#0E1F48", marginTop: window.innerWidth >= 640 ? "200px" : "0px", marginRight: window.innerWidth >= 640 ? "0px" : "0px", width: window.innerWidth >= 640 ? "auto" : "200px", padding: window.innerWidth >= 640 ? "0px" : "10px" }
                                             }
                                             onMouseEnter={() => window.innerWidth >= 640 && setHoveredIndex(index)}
                                             onMouseLeave={() => window.innerWidth >= 640 && setHoveredIndex(null)}
                                        >
                                             {item.description}
                                        </p>
                                   )}
                              </div>
                         </div>
                    ))}
               </div>
               <br />
               <br />
          </div>
     );
};

export default ThisYear;
