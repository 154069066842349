import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="mt-[10vh] px-6 py-10 max-w-4xl mx-auto">
            <h1 className="text-3xl font-bold text-center mb-6">Privacy Policy</h1>
            <p className="text-gray-600 text-center mb-4">Last updated: March 28, 2025</p>
            <p className="mb-4">
                This Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your information
                when You use the Service and tells You about Your privacy rights and how the law protects You.
            </p>
            <p className="mb-6">
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of
                information in accordance with this Privacy Policy.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Interpretation and Definitions</h2>

            <h3 className="text-xl font-medium mt-6 mb-2">Interpretation</h3>
            <p className="mb-4">
                The words with capitalized initials have meanings defined under specific conditions. These definitions apply regardless of
                singular or plural use.
            </p>

            <h3 className="text-xl font-medium mt-6 mb-2">Definitions</h3>
            <p className="mb-4">For the purposes of this Privacy Policy:</p>
            <ul className="list-disc list-inside space-y-2 mb-6">
                <li><strong>Account:</strong> A unique account created for You to access our Service.</li>
                <li><strong>Affiliate:</strong> An entity under common control with the Company, owning 50% or more shares.</li>
                <li><strong>Application:</strong> Refers to <strong>Techniche</strong>, the software program provided by the Company.</li>
                <li><strong>Company:</strong> (also referred to as "We", "Us", or "Our") refers to <strong>Techniche, New Sac, IIT Guwahati</strong>.</li>
                <li><strong>Country:</strong> Refers to Assam, India.</li>
                <li><strong>Device:</strong> Any device that can access the Service (computer, mobile, tablet, etc.).</li>
                <li><strong>Personal Data:</strong> Information that relates to an identifiable individual.</li>
                <li><strong>Service:</strong> Refers to the Application.</li>
                <li><strong>Service Provider:</strong> A third-party entity processing data on behalf of the Company.</li>
                <li><strong>Usage Data:</strong> Data collected automatically from using the Service (e.g., page visit duration).</li>
                <li><strong>You:</strong> The individual or legal entity accessing or using the Service.</li>
            </ul>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Collecting and Using Your Personal Data</h2>

            <h3 className="text-xl font-medium mt-6 mb-2">Types of Data Collected</h3>
            <h4 className="text-lg font-medium mt-4">Personal Data</h4>
            <p className="mb-4">While using Our Service, We may collect certain personally identifiable information:</p>
            <ul className="list-disc list-inside space-y-2 mb-6">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, ZIP/Postal code, City</li>
                <li>Usage Data</li>
            </ul>

            <h4 className="text-lg font-medium mt-4">Usage Data</h4>
            <p className="mb-4">
                Usage Data is collected automatically and may include information such as IP address, browser type, time spent on pages,
                and device identifiers.
            </p>

            <h3 className="text-xl font-medium mt-6 mb-2">Use of Your Personal Data</h3>
            <p className="mb-4">The Company may use Personal Data for purposes such as:</p>
            <ul className="list-disc list-inside space-y-2 mb-6">
                <li>Providing and maintaining our Service</li>
                <li>Managing Your account</li>
                <li>Fulfilling contractual obligations</li>
                <li>Sending updates, offers, and notifications</li>
                <li>Analyzing usage trends and improving services</li>
            </ul>

            <h3 className="text-xl font-medium mt-6 mb-2">Retention of Your Personal Data</h3>
            <p className="mb-4">
                We retain Personal Data as long as necessary for legal compliance and business needs. Usage Data is retained for analysis
                and service improvement.
            </p>

            <h3 className="text-xl font-medium mt-6 mb-2">Security of Your Personal Data</h3>
            <p className="mb-4">
                While we use industry-standard measures to protect your data, no method of transmission over the Internet is 100% secure.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Children's Privacy</h2>
            <p className="mb-4">
                Our Service does not address individuals under 13. If you believe a child has provided us with personal data, contact us
                to remove it.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to this Privacy Policy</h2>
            <p className="mb-4">
                We may update this Privacy Policy and will notify You via email or a notice on our Service. The updated date will reflect
                the latest changes.
            </p>

            <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
            <p className="mb-4">If you have any questions about this Privacy Policy, contact us:</p>
            <ul className="list-disc list-inside space-y-2">
                <li>Email: <a href="mailto:info@technicheiitg.in" className="text-blue-500 hover:underline">info@technicheiitg.in</a></li>
            </ul>
        </div>
    );
};

export default PrivacyPolicy;
