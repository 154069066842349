import homeVideo from '../../../assets/abouther.webm';
import homePixels from '../../../assets/homePixels.png';
import './style.css';

const HomeAbout = () => {
  return (
    <div className='home-wrapper' style={{ display: 'flex', width: '100vw' }}>
      <div className="home-left-wrapper" style={{ backgroundColor: '#F3FF63' }}>

        <div className='home-slider-wrapper'>
          <div className='pixels-slider'>
            <img src={homePixels} alt='' />
            <img src={homePixels} alt='' />
          </div>
        </div>

        <div className='about-techniche-heading-wrapper' style={{ marginLeft: '8vw', zIndex: '2' }}>
          <div className='about-techniche-heading' style={{ color: '#2D48D5' }}>
            About Techniche
          </div>
          <div className='about-main-heading' style={{ margin: '10px 0px 20px 0px', color: '#2D48D5' }}>
            Where <span style={{ fontStyle: 'italic', color: '#2D48D5' }}>Innovation</span> Meets Collaboration
          </div>
          <div className='about-techniche-heading-lower' id='about-heading-details' style={{ color: '#2D48D5' }}>
            The Annual Techno-Management fest of Indian Institute of Technology (IITG), Guwahati, the largest in North-East India.
          </div>
        </div>

      </div>

      <div className="home-right-wrapper" style={{ zIndex: '1' }}>
        <video
          src={homeVideo}
          style={{ width: '100%', height: '100%' }}
          autoPlay
          loop
          muted
          playsInline
        />
      </div>
    </div>
  );
};

export default HomeAbout;
