import NexusImg from "../../../assets/nexus.png"
import LeftButton from '../../../assets/arrowleft.png';
import RightButton from '../../../assets/arrowright.png';
import MohitImg from "../../../assets/mohityt.png"
import SourabhImg from "../../../assets/SourabhAccenture.png"
import AshokImg from "../../../assets/ashoksethi.png"
import ArrowLeft from "../../../assets/ArrowLeftEvent.png"
import { contacts , schedule } from './data'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import Tile from "../SpeakersTile/SpeakersTile";
import './Nexus.css';
import CursorAnimation from '../../../CursorAnimation'
import Footer from "../../../Footer/Footer";

const Nexus = ()=>{

    const [current , setCurrent ] = useState(0)
    const overviewRef = useRef(null)
    const scheduleRef = useRef(null)
    const pastPanelRef = useRef(null)
    const contactRef = useRef(null)
    const registerRef = useRef(null)
    
    useEffect(()=>{

        const callback = ()=>{
            [overviewRef.current  ,scheduleRef.current, pastPanelRef.current, contactRef.current, registerRef.current].map((ref)=>{
                const rect = ref.getBoundingClientRect()
                if((100*rect.y/window.innerHeight)<30 && (100*rect.y/window.innerHeight)>0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll' , callback)
        return ()=>{window.removeEventListener('scroll', callback)}
    },[])
    

    return(
        <>
        <div className="singularevent-outer-wrapper" style={{paddingTop:'12vh'}}>
            <CursorAnimation/>
            <div className="EventNav" style={{display: 'flex', justifyContent: 'space-around' ,width: '100vw',height: '12vh', overflow: 'hidden',paddingTop: '1vw', paddingBottom: '8vw', paddingLeft:'8vw', paddingRight:'8vw', flexWrap:'wrap', zIndex:'95'}}>

                <div className="event-sidenav"  style={{ color: '#808182' , marginRight: '2%', zIndex:'97', cursor:'pointer'}}>
                    <Link to="/lectureseries"className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Lecture Series</Link>
                </div>
                <Link to={`/lectureseries`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={LeftButton}  style={{cursor:'pointer'}}/></button></Link>
                
                <div className="event-nav" style={{marginLeft: 'auto', marginRight: 'auto', display: 'inline', zIndex:'95'}}>
                    <i  style={{fontFamily: 'That_New_Pixel', color: '#2D48D5'}}>Nexus</i>
                </div>

                <Link to={`/initiatives`} style={{alignContent: 'center', alignItems: 'center', zIndex:'95'}}><button className='event-navbutton' style={{alignContent: 'center', alignItems: 'center'}}><img src={RightButton}   style={{cursor:'pointer'}}/></button></Link>
                <div className="event-sidenav"  style={{fontFamily: 'That_New_Pixel', color: '#808182', marginLeft: '2%', zIndex:'95'}}>
                    <Link to="/initiatives" className="event-sidenav-module" style={{fontFamily: 'That_New_Pixel', cursor:'pointer'}}>Initiatives</Link>
                </div>

            </div>
            <div className="singularevent-inner-wrapper">
                <div className="singularevent-navbar">
                    <ul style={{display:'flex' , listStyle:'none',backgroundColor:'#F3FF63', justifyContent:'space-evenly'}}>
                        <li onClick={()=>{setCurrent('singularevent-overview')}}>
                            <a style={current==='singularevent-overview'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#'>Overview</a></li>
                        <li onClick={()=>{setCurrent('singularevent-event')}}>
                            <a style={current==='singularevent-event'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#events-scroll-tracker'>Schedule</a></li>
                        <li onClick={()=>{setCurrent('singularevent-registration')}}>
                            <a style={current==='singularevent-registration'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#registration-scroll-tracker'>Past Panels</a></li>
                        <li onClick={()=>{setCurrent('singularevent-prizes')}}>
                            <a style={current==='singularevent-contact'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#singularevent-contact'>Contact</a></li>
                        <li onClick={()=>{setCurrent('singularevent-register')}}>
                            <a style={current==='singularevent-register'?{color:'#2D48D5',fontStyle:'italic'}:{color:'#9c9c9c'}} href='#singularevent-contact'>Register</a></li>
                    </ul>
                </div>
                <div className="singularevent-content-outer">
                    <div className="singularevent-content-border clip-bg" style={{backgroundColor:'black',padding:'0px 4px 4px 4px'}}>
                        <div className="singularevent-content-inner clip-bg">
                            <div ref={overviewRef} id="singularevent-overview">
                                <div className="singularevent2-content-wrapper" >
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Overview
                                    </div>
                                    <div className="singularevent-content-details">
                                        <div id='events-scroll-tracker'></div> 
                                        Nexus serves as an annual nexus for undergraduates keen on management, entrepreneurship, and industry. It strives to narrow the gap between academia and the professional arena, curated by entrepreneurs, venture capitalists, tech experts, and consultants.Nexus hosts varied events including interactive sessions, networking lunches, panel discussions, keynote speeches, and hackathons, nurturing personal and professional development while fostering a vibrant community of aspiring professionals.
                                    </div>    
                                </div>    
                            </div>
                            <div className='singularevent2-img-wrapper'>
                                  <div className='clip-class' style={{padding:'2px',backgroundColor:'black'}}>                               
                                      <img src={NexusImg}  alt=""/>
                                  </div>
                            </div>
                            <div ref={scheduleRef} id="singularevent-schedule">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Schedule
                                </div>
                                <div className="singularevent-content-details">
                                    {schedule.map((item)=>{
                                        return(
                                            <div key={item.id} style={{margin:'10px'}}>
                                                <div style={{fontSize:'1.3rem',color:'#F3FF63', fontWeight:'300'}}>{item.date}{item.id<3 && <hr/>}</div>
                                                <ul className="schedule-day-date" style={{fontSize:'100',fontSize:'1rem', letterSpacing:'0.03rem'}}>
                                                    <li>{item.list[0]}</li>
                                                    <li>{item.list[1]}</li>
                                                    <li>{item.list[2]}</li>
                                                </ul>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div ref={pastPanelRef} id="singularevent-event">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Past Panels
                                </div>
                                <div className="singularevent-content-details">
                                    <Tile title="BGMI" img={MohitImg} color="white" Name="Mohit Tyagi" About="Indian Educator" />
                                    <Tile title="BGMI" img={SourabhImg} color="white" Name="Sourabh Mukherjee" About="VP, Accenture" />
                                    <Tile title="BGMI" img={AshokImg} color="white" Name="Ashok Sethi" About="COO, Tata Power" />
                                    <div id='registration-scroll-tracker'></div>
                                </div>
                            </div>
            
                            <div ref={contactRef} id="singularevent-contact">
                                <div className="singularevent-content-heading">
                                    <img className='singularevent-vector' src={require('../../../assets/EventsHead.png')} alt=" "/>Contact
                                </div>
                                <div className="singularevent-content-details">
                                    {contacts.map((item)=>{
                                        return(
                                            <div style={{margin:'10px'}}>
                                                <p style={{fontWeight:'500', fontSize:'1.2rem', fontFamily:"FK_Grotesk"}}>{item.name}</p>
                                                <p style={{fontWeight:'100', fontSize:'1rem', fontFamily:"FK_Grotesk"}}>{item.phone}</p>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div ref={registerRef} className="singularevent-register">
                              <Link to="https://unstop.com/p/nexus-the-corpo-management-conference-techniche-iit-guwahati-1119924">
                                <div className='clip-btn singularevent-btn-outer'  style={{backgroundColor:'black',width:'max-content' , padding:'2px'}}>
                                    <div className='clip-btn singularevent-btn-inner'style={{fontFamily:"FK_Grotesk", backgroundColor:'#F3FF63',width:'max-content',fontSize:'1rem',padding:'10px 40px'}}>Register Now</div>
                                </div>
                              </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}
export default Nexus