// CAHeaderLogin.js
import React, { useEffect, useState } from 'react';
import "./ca-headerlogin.css";
import logo from "../CA-HeaderLogin/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../services/authContext';

function CAHeaderLogin() {
  const { user, setUser, logout } = useAuth(); 
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    localStorage.setItem('formSubmitted', 'false');
    logout();
    navigate('/ca');
  };

  useEffect(() => {
    if (user && user.sanitizedHash) {
    }
  }, [user]);

  return (
    <div className={`fixed top-0 w-[100vw] z-50 ${showMenu ? 'menu-open' : ''}`}>
      <nav className="canavbar">
        <div className="canavbar-logo">
          <Link to="/ca">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <ul className={`tracking-wider navbar-links ${showMenu ? 'show' : ''}`} >
          <li id="incentives" className='pr-0 ' ><Link to="/ca/caincentives" onClick={handleToggleMenu}>Incentives</Link></li>
          <li id="" className='pr-0 '><Link to="/ca/cafaq" onClick={handleToggleMenu}>FAQ</Link></li>
          {user && user.token && (
            <li id="dashboard" className="logout" ><Link to={`/cadashboard/${user.sanitizedHash}`} onClick={handleToggleMenu}>Dashboard</Link></li>
          )}
          {user && user.token ? (
        <li><button className='pr-0' onClick={handleLogout}>Logout</button></li>
      ) : (
        <li><Link to="/calogin" onClick={() => { navigate('/ca'); }}>Login/ Register</Link></li>
      )}
        </ul>
        <div className={`cahamburger-menu ${showMenu ? 'open' : ''}`} onClick={handleToggleMenu}>
          <div className="line" id="firstline" />
          <div className="line" />
          <div className="line" id="secondline" />
        </div>
      </nav>
    </div>
  );
}

export default CAHeaderLogin;