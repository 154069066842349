import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";

// Assets imports
import nhpc from "./assets/nhpc.png";
import annapurna from "./assets/annapurna.png";
import icfdr from "./assets/icfdr.png";
import medalIcon from './assets/image_2272d07c.png';
import healthcareIcon from './assets/image_df2cc0c.png';
import certificateIcon from './assets/Group 861.png';
import rfidIcon from './assets/image_4ae77f0a.png';
import refreshmentIcon from './assets/image_39c7a2ff.png';
import benefitsImage from './assets/image_e4d0c5f2.png';
import trophyicon from './assets/image.png';
import fundaaz from './assets/Fundaaz.png';
import savlon from '../../GhmAssets/h.png';
import tooyum from '../../GhmAssets/ty.png';
import maxprotein from '../../GhmAssets/m.png';
import reccy from '../../GhmAssets/r.png';
import storia from '../../GhmAssets/bev.png';
import paree from '../../GhmAssets/paree1.png';
import { color } from "framer-motion";
import { wrap } from "gsap";

// Sponsors Marquee Component
const sponsors = [
  { id: 1, name: "NHPC", image: nhpc, role: "Title Sponsor" },
  { id: 2, name: "Annapurna", image: annapurna, role: "Associate Title Sponsor" },
  { id: 3, name: "ICFDR", image: icfdr, role: "Presenting" },
  { id: 4, name: "fundaaz", image: fundaaz, role: "Refreshment Partner" },
  { id: 5, name: "savlon", image: savlon, role: "Health Partner" },
  { id: 6, name: "tooyum", image: tooyum, role: "Snacking Partner" },
  { id: 7, name: "maxprotein", image: maxprotein, role: "Snacking Partner" },
  { id: 8, name: "reccy", image: reccy, role: "Sponsor" },
  { id: 9, name: "storia", image: storia, role: "Beverage Partner" },
  { id: 10, name: "paree", image: paree, role: "Hygiene Partner" },




];

const sponsorsLoop = [...sponsors, ...sponsors];

const SponsorItem = ({ sponsor, isMobile }) => {
  const [hover, setHover] = useState(false);

  const itemStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "200px",
    margin: isMobile ? "0" : "0 30px",
    transform: hover ? "scale(1)" : "scale(0.9)",
    transition: "transform 0.5s ease-in-out",
  };

  const imageStyle = {
    width: "120px",
    height: "120px",
    objectFit: "contain",
    filter: "drop-shadow(0 0 10px rgba(0,0,0,0.1))",
  };

  const roleStyle = {
    fontFamily: "DM Sans, sans-serif",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "100%",
    letterSpacing: "4%",
    textAlign: "center",
    marginTop: "10px",
    color: "#0C0E3D",
  };

  return (
    <div
      style={itemStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={sponsor.image} alt={sponsor.name} style={imageStyle} />
      <p style={roleStyle}>{sponsor.role}</p>
    </div>
  );
};

const SponsorsMarquee = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 786);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);

  useEffect(() => {

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const marqueeContainerStyle = isMobile
    ? {
      background: "#fff6e4",
      overflow: "visible",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      left: isSmallScreen ? "-55px" : "-120px",
      margin: "0",
      border: "1px solid #fff6e4",
    }
    : {
      background:
        "linear-gradient(to right, rgba(255,255,255,0),#fff6e4), linear-gradient(to right, #fff6e4, #fff6e4)",
      padding: "0px",
      margin: "0px",
      position: "relative",
      left: "-120px",
      right: "0",
      overflow: "hidden",
      border: "1px solid #fff6e4",
    };

  const marqueeWrapperStyle = {
    display: isMobile ? "none" : "block",
  };

  const sponsorsStaticListStyle = {
    display: isMobile ? "flex" : "none",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    padding: "20px auto",
  };

  return (
    <div style={marqueeContainerStyle}>
      <div style={marqueeWrapperStyle}>
        <Marquee speed={50} gradient={true} pauseOnHover={true}>
          {sponsorsLoop.map((sponsor, index) => (
            <SponsorItem key={index} sponsor={sponsor} isMobile={false} />
          ))}
        </Marquee>
      </div>

      <div style={sponsorsStaticListStyle}>
        {sponsors.map((sponsor) => (
          <SponsorItem key={sponsor.id} sponsor={sponsor} isMobile={true} />
        ))}
      </div>
    </div>
  );
};

// Services Table Component
const ServicesTable = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const services = [
    { icon: medalIcon, label: 'Medals' },
    { icon: healthcareIcon, label: 'Healthcare Support' },
    { icon: certificateIcon, label: 'Participation Certificate' },
    { icon: rfidIcon, label: 'RFID Timed bib' },
    { icon: refreshmentIcon, label: 'Refreshment' },
    { icon: trophyicon, label: 'Bragging Rights' }
  ];

  const styles = {
    serviceTableContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto",
      height: "417px",
    },
    serviceTable: {
      display: "grid",
      gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
      gridTemplateRows: isMobile ? "repeat(3, auto)" : "repeat(2, auto)",
      width: "100%",
      borderCollapse: "collapse",
      backgroundColor: "#fff6e4",
    },
    serviceCell: {
      border: "1px solid #e0e0e0",
      padding: isMobile ? "15px 10px" : "30px 20px",
      textAlign: "center",
    },
    serviceContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    iconContainer: {
      marginBottom: "15px",
    },
    serviceIcon: {
      width: isMobile ? "40px" : "50px",
      height: isMobile ? "40px" : "50px",
      objectFit: "contain",
    },
    serviceLabel: {
      fontFamily: "'DM Sans', sans-serif",
      fontSize: isMobile ? "16px" : "18px",
      fontWeight: 500,
      color: "#333",
      margin: 0,
    },
  };

  return (
    <div style={styles.serviceTableContainer}>
      <div style={styles.serviceTable}>
        {services.map((service, index) => (
          <div key={index} style={styles.serviceCell}>
            <div style={styles.serviceContent}>
              <div style={styles.iconContainer}>
                <img
                  src={service.icon}
                  alt={service.label}
                  style={styles.serviceIcon}
                />
              </div>
              <p style={styles.serviceLabel}>{service.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Participation Benefits Section Component
function ParticipationBenefitsSection() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 786);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const styles = {
    eventDetailsContainer1: {
      flex: "0 0 auto",
    },
    flexContainerWithBenefits: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "flex-start",
      width: "100%",
    },
    participationBenefitsSection: {
      boxSizing: "border-box",
      flex: "0 0 auto",
      width: isMobile ? "100%" : "66.39%",
      padding: "0",
      paddingBottom: "177px",
      margin: "0",
      color: "#0e1f48",
      textAlign: isMobile ? "center" : "left",
    },
    italicHeadingTextStyle: {
      font: 'italic 400 48px/58px "DM Sans", sans-serif',
      color: "#0e1f48",
      textAlign: isMobile ? "center" : "left",
      fontSize: isSmallScreen ? "32px" : "48px",

    },
    benefitHeadingStyle: {
      fontSize: isSmallScreen ? "32px" : "48px",
      fontFamily: 'sans-serif',
      fontWeight: 700,
      lineHeight: '120%',
      letterSpacing: 0,
      textTransform: 'uppercase',
      color: "#0e1f48",
    },
    runnerBenefitsHeading: {
      boxSizing: "border-box",
      flex: "0 0 auto",
      width: "23.46%",
      padding: "0",
      margin: "0",
      font: '500 24px "DM Sans", sans-serif',
      color: "#0e1f48",
      display: isMobile ? "none" : "block",
      visibility: isMobile ? "hidden" : "visible",
    },
    participationBenefitsContainer: {
      boxSizing: "border-box",
      display: "flex",
      flex: "0 0 auto",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      width: "10.15%",
      paddingLeft: "15px",
    },
    imageContainer: {
      boxSizing: "border-box",
      display: isMobile ? "none" : "block",
      width: "226px",
      maxWidth: "initial",
      height: "269px",
      marginRight: "-119px",
      zIndex: 1,
      visibility: isMobile ? "hidden" : "visible",
    },
  };

  return (
    <div style={styles.eventDetailsContainer1}>
      <div style={styles.flexContainerWithBenefits}>
        <p style={styles.participationBenefitsSection}>
          <span style={styles.italicHeadingTextStyle}>
          Something for Our Runners<br />
          </span>
          <span style={styles.benefitHeadingStyle}>
            PARTICIPATION PERKS
          </span>
        </p>
        <p style={styles.runnerBenefitsHeading}>*Only for Regsitered Runners</p>
        <div style={styles.participationBenefitsContainer}>
          <img
            src={benefitsImage}
            alt=""
            style={styles.imageContainer}
          />
        </div>
      </div>
      <ServicesTable />
    </div>
  );
}
// Corporate Partners Section Component

function CorporatePartnersSection() {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 786);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const corporatePartnersStyles = {
    eventDetailsContainer2: {
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "flex-start",
      width: "100vw",
      padding: isSmallScreen ? "231px 55px 160px" : "231px 119px 160px",
    },
    participationBenefitsHeading: {
      flex: "0 0 auto",
      padding: 0,
      margin: 0,
      marginTop: "200px",
      color: "#0e1f48",
      textAlign: isMobile ? "center" : "left",
    },
    italicHeadingTextStyle: {
      font: "italic 400 48px/58px 'DM Sans', sans-serif",
      color: "#0e1f48",
      textAlign: isMobile ? "center" : "left",
      fontSize: isSmallScreen ? "32px" : "48px",

    },
    benefitHeadingStyle: {
      fontFamily: 'sans-serif',
      fontWeight: 700,
      fontSize: 48,
      lineHeight: '120%',
      letterSpacing: 0,
      textTransform: 'uppercase',
      color: "#0e1f48",
      fontSize: isSmallScreen ? "32px" : "48px",
    },
    sponsorSectionStyles: {
      boxSizing: "border-box",
      display: "flex",
      flex: "0 0 auto",
      flexDirection: "row",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginTop: "80px",
      width: "100vw",
    },
  };
  return (
    <div style={corporatePartnersStyles.eventDetailsContainer2}>
      <ParticipationBenefitsSection />
      <p style={corporatePartnersStyles.participationBenefitsHeading}>
        <span style={corporatePartnersStyles.italicHeadingTextStyle}>
        Who were our<br />
        </span>
        <span style={corporatePartnersStyles.benefitHeadingStyle}>CORPORATE PARTNERS?</span>
      </p>
      <div style={corporatePartnersStyles.sponsorSectionStyles}>
        <SponsorsMarquee />
      </div>
    </div>
  );
}

// Health Event Section Component
const healthEventStyles = {
  eventDetailsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    width: "100%",
    height: "417px",
    marginTop: "-97px",
  },
};

function HealthEventSection() {
  return (
    <div style={healthEventStyles.eventDetailsContainer}>
      <ServicesTable />
    </div>
  );
}

// Main Component
const GHMBenefitsAndSponsors = () => {
  return (
    <div style={{ display: "inline-block", width: "100vw", background: "#fff6e4" }} data-ignore="used only for top most containter width">
      <CorporatePartnersSection />
    </div>
  );
};

export default GHMBenefitsAndSponsors;


