import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CALeaderboard.css';
import ProfileCard from '../CADasboard/ProfileCard'; // Make sure the import path is correct
import img1 from './podium.png';
import third from './third.png';
import second from './second.png';
import first from './first.png';
import { div } from 'three/examples/jsm/nodes/Nodes.js';
import Navbar from '../CA-Home/nav';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const CALeaderboard = () => {
    const [leaderboard, setLeaderboard] = useState([]);
    const [loggedInUserName, setLoggedInUserName] = useState('');

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                const response = await axios.get(baseURL+'/catasksupload/leaderboard');
                setLeaderboard(response.data);
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
            }
        };

        fetchLeaderboard();

        const name = localStorage.getItem('name');
        setLoggedInUserName(name);
    }, []);

    return (
        <div className="calb_container">
             <Navbar/>
            <h1>Leaderboard</h1>
            <div className='podium-container'>
                {leaderboard.slice(1,2).map((entry,index)=>(
                    <div className='podium2'>
                        <div>
                            <img src={second} alt="" className='position-star-2'/>
                            <img src={img1} alt="" />
                            
                        </div>
                        <div className='entry-name'>{entry.name}</div>
                            <div className='entry-points'>{entry.points}</div>
                    </div>
                    
                ))}
                {leaderboard.slice(0,1).map((entry,index)=>(
                    <div className='podium1'>
                        <div>
                        <img src={first} alt="" className='position-star-1'/>
                            <img src={img1} alt="" />
                            </div>
                            <div className='entry-name'>{entry.name}</div>
                            <div className='entry-points'>{entry.points}</div>
                    </div>
                    
                ))}
                {leaderboard.slice(2,3).map((entry,index)=>(
                    <div className='podium3'>
                        <div >
                        <img src={third} alt="" className='position-star-3'/>
                            <img src={img1} alt="" className='podium-img'/></div>
                            <div className='entry-name'>{entry.name}</div>
                            <div className='entry-points'>{entry.points}</div>
                            
                    </div>
                    
                ))}
            </div>
            {/* <table className="calb_table">
                <thead className='table-head'>
                    <tr>
                        <th>Position</th>
                        <th>Causer</th>
                        <th>Points</th>
                        <th>Profile</th> 
                    </tr>
                </thead>
                <tbody>
                    {leaderboard.slice(3).map((entry, index) => ( // Display entries with index > 3
                        <tr key={entry.id} className={entry.name === loggedInUserName ? 'highlighted' : ''}>
                            <td>{index + 4}</td>
                            <td>{entry.name}</td>
                            <td className='table-points'>{entry.points}</td>
                            <td>{entry.ProfileCard && <ProfileCard profileData={entry.ProfileCard} />}</td> 
                        </tr>
                    ))}
                </tbody>
            </table> */}
            <div className='table-container'>
                <div className='heading-row'>
                    
                    <div className='position-heading'>Position</div>
                    <div className='name-heading'>Name</div>
                    <div className='leader-points'>Points</div>
                </div>
                <div className='table-entries'>
                {leaderboard.slice(0).map((entry, index) => ( // Display entries with index > 3
                        <div className='stats-row'>
                            <div  className='leader-position'>{index+1}</div>
                            <div className='leader-name'>{entry.name}</div>
                            <div className='points-div-leader'>{entry.points}</div>
                        </div>
                        

                    ))}
                </div>
            </div>
        </div>
    );
};

export default CALeaderboard;