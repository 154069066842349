const prizes = [
    {position:'Winner' , prize:'Rs.4,00,000/-'},
    {position:'1st Runner Up' , prize:'Rs.3,00,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.2,00,000/-'},
    {position:'3nd Runner Up' , prize:'Rs.50,000/-'},
    {position:'4nd Runner Up' , prize:'Rs.50,000/-'}
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Naman Karwa',phone:'+91 93777 80309'},
    {name:'Khushi Aggarwal',phone:'+91 81468 43309'},
]
const schedule  = [
    {item:1 , date:'31st August',list:['Induction and Interactive Session','Networking Lunch','Hackathon(Session-I)']},
    {item:2 , date:'1st September',list:['Keynote','Speed Mentoring','Hackathon(Session-II)']},
    {item:3 , date:'2nd September',list:['Workshop','Closing Ceremony','Hackathon(Session-III)']}
]

export {prizes, contacts , schedule}