import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import NewNavbar from "./navbarnew.js";
import './newGhmRegister.css';
import vec1 from '../GhmAssets/Vector 1058-2.png';
import vec2 from '../GhmAssets/Vector 1059.png';

const baseURL = process.env.NODE_ENV === "production" ? "https://techniche.org.in/api" : "http://localhost:3001/api";
const CryptoJS = require("crypto-js");

const GHMRegistrationForm = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        Name: '',
        Age: '',
        Gender: '',
        Organization: '',
        Contact: '',
        Email: '',
        Country: '',
        State: '',
        City: '',
        CategoryofRace: '',
        GeneralChampionship: '',
        Mediaform: '',
        IsPaid: ''
    });

    const [showPayButton, setShowPayButton] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [registrationsOpen, setRegistrationsOpen] = useState(true);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === "CategoryofRace") {
            setShowPayButton(value === "21");
        }
    };

    const redirectToMeraEvents = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/ghm/ghmregister', formData);
            const { GHM_ID } = response.data;

            // Encrypt
            const ciphertext = CryptoJS.AES.encrypt(GHM_ID, "iitgtechniche").toString().replace(/\//g, '_');
            const lastFiveLetters = GHM_ID.slice(-5);
            const domain = process.env.NODE_ENV === "production" ? "https://techniche.org.in"
                : "http://localhost:3000";
            const redirectUrl = `${domain}/ghm/21confirm/${ciphertext}${lastFiveLetters}`;

            window.location.href = 'https://www.meraevents.com/ticketWidget?eventId=263695&ucode=organizer&wcode=9063CD-9063CD-333333-9063CD-&theme=1&redirectUrl=' + redirectUrl;

        } catch (error) {
            let errorMessage = '';
            if (error.response && error.response.status === 409 && formData.CategoryofRace === "21") {
                errorMessage = 'You have already registered for the marathon. Kindly check your email for further information.';
            }
            else {
                errorMessage = 'Registration failed. Please check your credentials.';
            }
            setErrorMessage(errorMessage);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseURL + '/ghm/ghmregister', formData);
            const { GHM_ID } = response.data;

            const ciphertext6 = CryptoJS.AES.encrypt(GHM_ID, "iitgtechniche").toString().replace(/\//g, '_');
            const lastFiveLetters = GHM_ID.slice(-5);
            const redirectUrl6 = `https://techniche.org.in/ghm/6confirm/${ciphertext6}${lastFiveLetters}`;
            window.location.href = redirectUrl6;

        } catch (error) {
            let errorMessage = '';

            if (error.response && error.response.status === 409 && formData.CategoryofRace === "6") {
                errorMessage = 'You have already registered for the marathon. Kindly check your email for further information.';
            } else {
                errorMessage = 'Registration failed. Please check your credentials.';
            }
            setErrorMessage(errorMessage);
        }
    };

    return (
        <div className="ghm-register-page ">
            <NewNavbar />
            <div className="relative w-full h-full">
        {/* Top Right Vector - Hidden on Mobile */}
        <img 
            src={vec1}
            alt="Top Right Vector" 
            className="absolute top-0 right-0 w-56 h-56 lg:w-72 lg:h-72 hidden lg:block"
        />

        {/* Center Left Vector - Lowered & Hidden on Mobile */}
        <img 
            src={vec2}
            alt="Center Left Vector" 
            className="absolute top-[90] left-0 transform -translate-y-1/6 w-40 h-45 lg:w-50 lg:h-45 hidden lg:block mt-40"
        />
    </div>
        
    


            <div className="ghm-register-container">
                <div className="ghm-header">
                    <h1 className="ghm-register-title" style={{ color: 'rgba(14, 31, 72, 1)' }}>
                        {registrationsOpen ? (
                            <>
                                <span className="register-title-line-1">Register for</span>
                                <span className="register-title-line-2">GUWAHATI HALF MARATHON</span>
                            </>
                        ) : (
                            "REGISTRATIONS ARE CLOSED! SEE YOU AT THE STARTING LINE"
                        )}
                    </h1>
                </div>

                <div className="ghm-form-card">
                    <form onSubmit={handleSubmit} className="ghm-form">
                        <div className="form-section">
                            <h2 className="section-title">Personal Details</h2>
                            <div className="form-group">
                                <label htmlFor="Name">Full Name*</label>
                                <input
                                    type="text"
                                    id="Name"
                                    name="Name"
                                    placeholder="Enter your full name"
                                    value={formData.Name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div className="form-row">
                                <div className="form-group half">
                                    <label htmlFor="Age">Age*</label>
                                    <input
                                        type="number"
                                        id="Age"
                                        name="Age"
                                        min="0"
                                        placeholder="Enter your age"
                                        value={formData.Age}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group half">
                                    <label htmlFor="Gender">Gender*</label>
                                    <select
                                        name="Gender"
                                        id="Gender"
                                        value={formData.Gender}
                                        onChange={handleChange}
                                        required
                                    >
                                        <option value="" disabled>Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="rns">Rather Not Say</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <h2 className="section-title">Organization</h2>
                            <div className="form-group">
                                <label htmlFor="Organization">College/Institution/Company*</label>
                                <input
                                    type="text"
                                    id="Organization"
                                    name="Organization"
                                    placeholder="Enter your College/Institution/Company"
                                    value={formData.Organization}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>

                        <div className="form-section">
                            <h2 className="section-title">Contact Information</h2>
                            <div className="form-row">
                                <div className="form-group half">
                                    <label htmlFor="Contact">Contact Number*</label>
                                    <input
                                        type="text"
                                        id="Contact"
                                        name="Contact"
                                        placeholder="Enter your contact details"
                                        value={formData.Contact}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group half">
                                    <label htmlFor="Email">Email Address*</label>
                                    <input
                                        type="email"
                                        id="Email"
                                        name="Email"
                                        placeholder="Enter your email"
                                        value={formData.Email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="form-row three-col">
                                <div className="form-group">
                                    <label htmlFor="Country">Country*</label>
                                    <input
                                        type="text"
                                        id="Country"
                                        name="Country"
                                        placeholder="Country"
                                        value={formData.Country}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="State">State*</label>
                                    <input
                                        type="text"
                                        id="State"
                                        name="State"
                                        placeholder="State"
                                        value={formData.State}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="City">City/District*</label>
                                    <input
                                        type="text"
                                        id="City"
                                        name="City"
                                        placeholder="City"
                                        value={formData.City}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="form-section">
                            <h2 className="section-title">Marathon Details</h2>
                            <div className="form-group">
                                <label htmlFor="Category">Category*</label>
                                <select
                                    name="CategoryofRace"
                                    id="Category"
                                    value={formData.CategoryofRace}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="6">6km</option>
                                    <option value="21">21km</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="GeneralChampionship">Would you like to participate in General Championship?*</label>
                                <select
                                    name="GeneralChampionship"
                                    id="GeneralChampionship"
                                    value={formData.GeneralChampionship}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="YES">YES</option>
                                    <option value="NO">NO</option>
                                </select>

                                <div className="info-accordion">
                                    <button type="button" className="accordion-toggle" onClick={toggleAccordion}>
                                        {isOpen ? "Hide Information" : "What is General Championship?"}
                                    </button>

                                    {isOpen && (
                                        <div className="accordion-content">
                                            <p>The General Championship trophy will be awarded to the college, institution, or group whose total distance covered by its runners is the highest. To find the total distance covered, sum the distances of all runners.</p>
                                            <p>For example, if 10 runners cover 21 km each and 15 runners cover 6 km each, the total is 300 km.</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="Mediaform">How did you get to know about GHM?*</label>
                                <select
                                    name="Mediaform"
                                    id="Mediaform"
                                    value={formData.Mediaform}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="" disabled>Select</option>
                                    <option value="Instagram">Instagram</option>
                                    <option value="Facebook">Facebook</option>
                                    <option value="NewsPaper">NewsPaper</option>
                                    <option value="NewsChannels">NewsChannels</option>
                                    <option value="Posters/Banners">Posters/Banners</option>
                                    <option value="Brand Ambassador">Brand Ambassador</option>
                                    <option value="Institute">Institute</option>
                                    <option value="Friends">Friends</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                        </div>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}

                        <div className="form-actions">
                            {formData.CategoryofRace === "6" && (
                                <button type="submit" className="register-btn" onClick={handleSubmit}>
                                    Register Now
                                </button>
                            )}

                            {formData.CategoryofRace === "21" && (
                                <button type="submit" className="register-btn pay-btn" onClick={redirectToMeraEvents}>
                                    Pay Now & Register
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default GHMRegistrationForm;