import React from 'react';
import './Eventcard.css';
import { Link } from 'react-router-dom';

function EventCardEscalade({ eventName, isRegistered, eventImage, registeredlink, registerlink, addteamlink, submissionlink, viewteamlink, teammember}) {
  return (
    <div className="dashboard-event-card">
      <img src={eventImage} alt={`${eventName} logo`} className="dashboard-event-card-image" />
      <h3 className="dashboard-event-card-title">{eventName}</h3>
      <p className="dashboard-event-card-status">
        {isRegistered ? (
            <>
            {!teammember ? (
                <div className='registered-container-leader'>
                <p>You have already registered for this event</p>
                <p style={{ fontSize:"0.9em", color:"red"}}>Complete the payment through the emailed link. Ignore if already done.</p>
                <div className='registered-button'>
                    <Link to={addteamlink} className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200',  paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >Add team</Link>
                </div>
                {submissionlink && (
                  <div className='registered-button'>
                    <Link to={submissionlink} className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900 hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem', fontWeight: '200', paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh' }}>Submissions</Link>
                  </div>
                )}
              </div>
            ) : (
                <div className='registered-container'>
                <p>You have been added to a team !</p>
                <div className='viewteam-button'>
                    <Link to={viewteamlink} className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200',  paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >See Team Details</Link><br />
                </div>
                <div className='registered-button'>
                   <Link to="/robotics/escalade" className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200',  paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >View more details</Link><br />
                </div>
              </div>
            )}
            </>
        ) : (
        <div>
          <div className='registered-button'>
             <Link to={registeredlink} className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200',  paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >See more details</Link><br />
          </div>
          <div className='registered-button'  style={{ marginTop:"5vh"}}>
             <Link to={registerlink} className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200',  paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >Register Now !</Link><br />
          </div>
          
        </div>
        )}
      </p>
    </div>
  );
}

export default EventCardEscalade;