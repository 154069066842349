const prizes = [
    {position:'Winner' , prize:'Rs.40,000/-'},
    {position:'1st Runner Up' , prize:'Rs.30,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.20,000/-'},
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Yash',phone:'+91 7011583800'},
    {name:'Sameeran',phone:'+91 9763586376'},
]
const schedule  = [
    {item:1 , date:'5th August',list:['Abstract Submission Deadline']},
    {item:2 , date:'29th August',list:['Final Round']},
    {item:3 , date:'',list:['']}
]

export {prizes, contacts , schedule}