import UVDCImg from "../../../../assets/uvdc.png"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { prizes, contacts, schedule } from './data'
import { useEffect, useRef, useState } from 'react'
import Footer from "../../../../Footer/Footer"
import { Link } from 'react-router-dom';
import CursorAnimation from '../../../../CursorAnimation'
import SmallImage from "../../Small-Image/Small-Image"

const UVDC = () => {

    const [current, setCurrent] = useState(0)
    const overviewRef = useRef(null)
    const eventRef = useRef(null)
    const prizesRef = useRef(null)
    const contactRef = useRef(null)
    const registerRef = useRef(null)

    useEffect(() => {

        const callback = () => {
            [overviewRef.current, eventRef.current,
            prizesRef.current, contactRef.current, registerRef.current].map((ref) => {
                const rect = ref.getBoundingClientRect()
                if ((100 * rect.y / window.innerHeight) < 30 && (100 * rect.y / window.innerHeight) > 0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll', callback)
        return () => { window.removeEventListener('scroll', callback) }
    }, [])

    const prizeCard = (item) => {
        return (
            <div className='singularevent-prize-card-outer clip-class-prize'>
                <div className='singularevent-prize-card-inner clip-class-prize'>
                    <p style={{ color: '#2D48D5', fontWeight: '300', fontSize: window.innerWidth > 400 ? '0.9rem' : '0.7rem' }}>{item.position}</p>
                    <p style={{ color: '#2D48D5', fontWeight: '600', fontSize: window.innerWidth > 400 ? '1.4rem' : '0.9rem' }}>{item.prize}</p>
                </div>
            </div>
        )
    }



    return (
        <>
            <div className="singularevent-outer-wrapper">
                <CursorAnimation />
                <div className="singularevent-title">
                    <div className='go-left-arrow'>
                        <Link to="/competitions"><img src={ArrowLeft} /></Link>
                    </div>
                    UVDC
                    <div className='go-left-arrow'>

                    </div>
                </div>
                <div className="singularevent-inner-wrapper">
                    <div className="singularevent-navbar">
                        <ul style={{ display: 'flex', listStyle: 'none', backgroundColor: '#F3FF63', justifyContent: 'space-evenly' }}>
                            <li onClick={() => { setCurrent('singularevent-overview') }}>
                                <a style={current === 'singularevent-overview' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#'>Overview</a></li>
                            <li onClick={() => { setCurrent('singularevent-event') }}>
                                <a style={current === 'singularevent-event' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#events-scroll-tracker'>Event Details</a></li>
                            <li onClick={() => { setCurrent('singularevent-prizes') }}>
                                <a style={current === 'singularevent-prizes' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#prizes-scroll-tracker'>Prizes</a></li>
                            <li onClick={() => { setCurrent('singularevent-contact') }}>
                                <a style={current === 'singularevent-contact' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Contact</a></li>
                            <li onClick={() => { setCurrent('singularevent-register') }}>
                                <a style={current === 'singularevent-register' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Register</a></li>
                        </ul>
                    </div>
                    <div className="singularevent-content-outer">
                        <div className="singularevent-content-border clip-bg" style={{ backgroundColor: 'black', padding: '0px 4px 4px 4px' }}>
                            <div className="singularevent-content-inner clip-bg">
                                <div ref={overviewRef} id="singularevent-overview">
                                    <div className='singularevent-img-robo-wrapper'>
                                        <SmallImage title="Robowars" img={UVDCImg} color="white" link="robotics/robowars" text="Learn More" />
                                    </div>
                                    <div className="singularevent-content-wrapper" style={{ margin: window.innerWidth > '650' ? '5vh 3vw 3vh 3vw' : '' }}>
                                        <div className="singularevent-content-heading">
                                            <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Overview
                                        </div>
                                        <div className="singularevent-content-details">
                                            <div id='events-scroll-tracker'></div>
                                            In collaboration with the IIT Guwahati Technology Innovation and Development Foundation (TIDF), Techniche presents UVDC-2024, a unique hybrid competition where innovation meets creativity. Open to both graduate and undergraduate students, this competition requires no physical construction of designs.
                                        </div>
                                    </div>
                                </div>
                                <div ref={eventRef} id="singularevent-event">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Event Details
                                    </div>
                                    <div className="singularevent-content-details2" style={{ margin: '1vh 0px 0px 0px' }}>
                                        Stage 1(Online) : Registered teams must submit a System Review Evaluation (SRE) report and a rendered video of no more than 20 seconds.
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                    <div className="singularevent-content-details2" style={{ margin: '1vh 0px 0px 0px' }}>
                                        Stage 2 (Offline): The top 12 teams from Stage 1 will present their designs in one-on-one sessions with an expert panel from IITG-TIDF.
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                </div>
                                <div ref={prizesRef} id="singularevent-prizes" style={{ marginTop: '5vh' }}>
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Prize Pool Worth Rs. 1,50,000/-
                                    </div>

                                    <div style={{ margin: '20px 0px' }}>
                                        <div>
                                            <div className='singularevent-prize-category-outer'>
                                                <div className='singularevent-prize-category-inner-1'>{prizeCard(prizes[0])}</div>
                                                <div className='singularevent-prize-category-inner-1' style={{ display: 'flex' }}>
                                                    {prizeCard(prizes[1])}
                                                </div>
                                                <div className='singularevent-prize-category-inner-1' style={{ display: 'flex' }}>
                                                    {prizeCard(prizes[2])}
                                                </div>

                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div id="singularevent-schedule">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Schedule
                                    </div>
                                    <div className="singularevent-content-details">
                                        {schedule.map((item) => {
                                            return (
                                                <div key={item.id} style={{ margin: '10px', fontFamily: "FK_Grotesk" }}>
                                                    <div style={{ fontSize: '1.2rem', color: '#F3FF63', fontWeight: '500', fontFamily: "FK_Grotesk" }}>{item.date}{item.id < 3 && <hr />}</div>
                                                    <ul className="schedule-day-date" style={{ fontSize: '100', fontSize: '1rem', letterSpacing: '0.03rem' }}>
                                                        <li>{item.list[0]}</li>
                                                        <li>{item.list[1]}</li>
                                                        <li>{item.list[2]}</li>
                                                    </ul>
                                                    <ul className="schedule-day-date" style={{ fontSize: '100', fontSize: '1rem', letterSpacing: '0.03rem' }}>
                                                        <li>{item.list[3]}</li>
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={contactRef} id="singularevent-contact">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Contact
                                    </div>
                                    <div className="singularevent-content-details">
                                        {contacts.map((item) => {
                                            return (
                                                <div style={{ margin: '10px' }}>
                                                    <p style={{ fontWeight: '500', fontSize: '1.2rem', fontFamily: "FK_Grotesk" }}>{item.name}</p>
                                                    <p style={{ fontWeight: '100', fontSize: '1rem', fontFamily: "FK_Grotesk" }}>{item.phone}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={registerRef} className="singularevent-register">
                                    {/* <Link to="https://unstop.com/competitions/the-underwater-vehicle-design-challenge-uvdc-techniche-iit-guwahati-1070700"> */}
                                    <div className='clip-btn singularevent-btn-outer' style={{ backgroundColor: 'black', width: 'max-content', padding: '2px' }}>
                                        <div className='clip-btn singularevent-btn-inner' style={{ fontFamily: "FK_Grotesk", backgroundColor: '#F3FF63', width: 'max-content', fontSize: '1rem', padding: '10px 40px' }}>Registrations Closed. Coming Soon for 2025!</div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default UVDC