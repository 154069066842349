import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Dashboard.css';
import TechexpoImage from './images/image 3.png';
import RobowarsImage from '../../assets/125.png';
import AquawarsImage from '../../assets/124.png';
import EscaladeImage from '../../assets/123.png';
import TrackTitansImage from '../../assets/127.png';
import { useAuth } from '../../../services/authContext';
import Profilepic from './images/44.png';
import EventCardTechExpo from './EventcardTechexpo.js';
import EventCardRobowars from './EventcardRobowars.js';
import EventCardAquawars from './EventcardAquawars.js';
import EventCardEscalade from './EventcardEscalade.js';
import EventCardTrackTitans from './EventcardTrackTitans.js';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

function Dashboard() {
  const { sanitizedHash } = useParams();
  const [userDataMain, setUserDataMain] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [registrationStatus, setRegistrationStatus] = useState({});
  const { isLoggedIn, login, logout } = useAuth();
  const [country, setCountry] = useState('');

  useEffect(() => {
    console.log('Name:', userDataMain.name);
  }, [userDataMain.name]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.post(`${baseURL}/user/verifyhash`, { sanitizedHash });
        const { name, t_id, email } = response.data;
        setUserDataMain({ name, t_id, email });
        localStorage.setItem("name", name);
        localStorage.setItem("t_id", t_id);
        localStorage.setItem("email", email);

        // Fetch registration status
        const registrationResponse = await axios.post(`${baseURL}/user/getregistrations`, { email, t_id });
        setRegistrationStatus(registrationResponse.data);
        console.log(registrationStatus)

        // if (registrationResponse.data.escalade) {
        //     const countryResponse = await axios.post(`${baseURL}/escalade/getcountry`, { email, t_id });
        //     setCountry(countryResponse.data.country);
        // }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [sanitizedHash]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="ca-dashboard-container">
      <div className="h-[40vh] w-[40vw] mt-20 text-white">
        <div className="user-profile-card">
          <div className="user-profile-card-d1">
            <div className="user-profilepic">
              <img src={Profilepic} alt='Profile' className='user-profile-image' />
            </div>
            <div className="user-profile-info">
              {localStorage.getItem("name") && <div className='user-profile-creds'>{localStorage.getItem("name")}</div>}
              {localStorage.getItem("email") && <div className='user-profile-creds'>{localStorage.getItem("email")}</div>}
              {localStorage.getItem("t_id") && <div className='tech-rep-id'>TechRep ID: {localStorage.getItem("t_id")}</div>}
            </div>
          </div>
        </div>
        <div className='events-registration'>
          <EventCardTechExpo
            eventName="TechExpo"
            isRegistered={registrationStatus.techexpo}
            eventImage={TechexpoImage}
            registeredlink="/techexpo"
            registerlink="/techexpo/register"
          />
          <EventCardRobowars
            eventName="Robowars"
            isRegistered={registrationStatus.robowars}
            eventImage={RobowarsImage}
            registeredlink="/robotics/robowars"
            registerlink="/robotics/robowars/register"
            addteamlink={registrationStatus.robowarsFoundBy === 'email' ? `/dashboard/${sanitizedHash}/rwaddteam` : null}
            submissionlink={registrationStatus.robowarsFoundBy === 'email' ? `/dashboard/${sanitizedHash}/rwsubmission` : null}
            viewteamlink={registrationStatus.robowarsFoundBy === 't_id' ? `/dashboard/${sanitizedHash}/rwviewteam` : null}
            teammember={registrationStatus.robowarsFoundBy === 't_id' ? true : false}
          />
          <EventCardAquawars
            eventName="Aquawars"
            isRegistered={registrationStatus.aquawars}
            eventImage={AquawarsImage}
            registeredlink="/robotics/aquawars"
            registerlink="/robotics/aquawars/register"
            addteamlink={registrationStatus.aquawarsFoundBy === 'email' ? `/dashboard/${sanitizedHash}/awaddteam` : null}
            submissionlink={registrationStatus.aquawarsFoundBy === 'email' ? `/dashboard/${sanitizedHash}/awsubmission` : null}
            viewteamlink={registrationStatus.aquawarsFoundBy === 't_id' ? `/dashboard/${sanitizedHash}/awviewteam` : null}
            teammember={registrationStatus.aquawarsFoundBy === 't_id' ? true : false}
          />
          <EventCardEscalade
            eventName="Escalade"
            isRegistered={registrationStatus.escalade}
            eventImage={EscaladeImage}
            registeredlink="/robotics/escalade"
            registerlink="/robotics/escalade/register"
            addteamlink={registrationStatus.escaladeFoundBy === 'email' ? `/dashboard/${sanitizedHash}/esaddteam` : null}
            submissionlink={country !== 'India' ? `/dashboard/${sanitizedHash}/essubmission` : null}
            viewteamlink={registrationStatus.escaladeFoundBy === 't_id' ? `/dashboard/${sanitizedHash}/esviewteam` : null}
            teammember={registrationStatus.escaladeFoundBy === 't_id' ? true : false}
          />
          <EventCardTrackTitans
            eventName="Track Titans"
            isRegistered={registrationStatus.tracktitans}
            eventImage={TrackTitansImage}
            registeredlink="/robotics/tracktitans"
            registerlink="/robotics/tracktitans/register"
            addteamlink={registrationStatus.tracktitansFoundBy === 'email' ? `/dashboard/${sanitizedHash}/ttaddteam` : null}
            submissionlink={registrationStatus.tracktitansFoundBy === 'email' ? `/dashboard/${sanitizedHash}/ttsubmission` : null}
            viewteamlink={registrationStatus.tracktitansFoundBy === 't_id' ? `/dashboard/${sanitizedHash}/ttviewteam` : null}
            teammember={registrationStatus.tracktitansFoundBy === 't_id' ? true : false}
          />
        </div>

      </div>
    </div>
  );
}

export default Dashboard;
