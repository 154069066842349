import { useState } from "react";
import { useSpring, animated } from "react-spring";

const Accordion = ({a}) => {
  const [isActive, setIsactive] = useState(false);
  const [isHigh, setIshigh] = useState(false);

  const toggleHandler = (e) => {
    setIsactive(!isActive);
    setIshigh(!isHigh);
  };
  const openAnimation = useSpring({
    from: { maxHeight: "0vh" },
    to: { maxHeight: isActive ? "15vh" : "0vh" },
    config: { duration: "400" },
  });
  const iconAnimation = useSpring({
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: isActive ? "rotate(180deg)" : "rotate(0deg)",
    },
    config: { duration: "300" },
  });
  return (
    <div className="carddd">
      <div className="entry">
        <div className="show " onClick={toggleHandler}>
          <p>{a.q}</p>
          <animated.div style={iconAnimation}>
            {isActive ? " - " : " + "}
          </animated.div>
        </div>

        <animated.div className="toggle" style={openAnimation}>
          {a.a}
        </animated.div>
        <div className="space"></div>
      </div>
    </div>
  );
};

export default Accordion;