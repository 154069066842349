import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedInCA, setIsLoggedInCA] = useState(localStorage.getItem('isLoggedInCA') === 'true' || false);
  const [token, setToken] = useState(localStorage.getItem('catoken') || null);
  const [user, setUser] = useState({
    name: localStorage.getItem('caname') || '',
    t_id: localStorage.getItem('cat_id') || '',
    token: localStorage.getItem('catoken') || '', 
    sanitizedHash: localStorage.getItem('casanitizedHash') || '',
    email: localStorage.getItem('caemail') || '', 
  });
  
  const login = (token, userData) => {
    setToken(null);
    setToken(token);
    setUser(userData);
    localStorage.setItem('isLoggedInCA', true);
    setIsLoggedInCA(true);
    localStorage.setItem('catoken', token);
    localStorage.setItem('caname', userData.name);
    localStorage.setItem('cat_id', userData.t_id);
    localStorage.setItem('casanitizedHash', userData.sanitizedHash);
    localStorage.setItem('catoken', userData.token);
    localStorage.setItem('caemail', userData.email);
  };

  const logout = () => {
  localStorage.setItem('isLoggedInCA', false);
  setToken(null);
  setUser(null);
  setUser({
    name: null,
    t_id: null,
    email: null,
    sanitizedHash: null,
    token: null,
  });
  localStorage.removeItem('catoken');
  localStorage.removeItem('caname');
  localStorage.removeItem('cat_id');
  localStorage.removeItem('caemail');
  localStorage.removeItem('casanitizedHash');
};



const [isLoggedInMain, setIsLoggedInMain] = useState(localStorage.getItem('isLoggedInMain') === 'true' || false);
const [userDataMain, setUserDataMain] = useState({
  name: localStorage.getItem('name') || '',
  t_id: localStorage.getItem('t_id') || '',
  sanitizedHash: localStorage.getItem('sanitizedHash') || '',
  email: localStorage.getItem('email') || '', 
});
const loginmain = (userDataMain) => {
  localStorage.setItem('isLoggedInMain', true);
  setIsLoggedInMain(true);
  setUserDataMain(userDataMain);
  localStorage.setItem('name', userDataMain.name);
  localStorage.setItem('t_id', userDataMain.t_id);
  localStorage.setItem('sanitizedHash', userDataMain.sanitizedHash);
  // localStorage.setItem('token', userDataMain.token);
  localStorage.setItem('email', userDataMain.email);
};
const logoutmain = () => {
  localStorage.setItem('isLoggedInMain', false);
  setIsLoggedInMain(false);
  setUserDataMain({
    name: '',
    t_id: '',
    email: '',
    sanitizedHash: '',
    // token: ''
  });
  localStorage.removeItem('name');
  localStorage.removeItem('t_id');
  localStorage.removeItem('email');
  localStorage.removeItem('sanitizedHash');
  // localStorage.removeItem('token');
};
  return (
    <AuthContext.Provider value={{ token, user, login, logout, isLoggedInMain, userDataMain, loginmain, logoutmain }}>
      {children}
    </AuthContext.Provider>
  );
};
