import CircuitSimImg from "../../../../assets/circuitsim.png"
import ArrowLeft from "../../../../assets/ArrowLeftEvent.png"
import { prizes, contacts, schedule } from './data'
import { useEffect, useRef, useState } from 'react'
import Footer from "../../../../Footer/Footer"
import { Link } from 'react-router-dom';
import SmallImage from "../../Small-Image/Small-Image"

const CircuitSimulation = () => {

    const [current, setCurrent] = useState(0)
    const overviewRef = useRef(null)
    const eventRef = useRef(null)
    const registrationRef = useRef(null)
    const prizesRef = useRef(null)
    const contactRef = useRef(null)
    const registerRef = useRef(null)

    useEffect(() => {

        const callback = () => {
            [overviewRef.current, eventRef.current, registrationRef.current,
            prizesRef.current, contactRef.current, registerRef.current].map((ref) => {
                const rect = ref.getBoundingClientRect()
                if ((100 * rect.y / window.innerHeight) < 30 && (100 * rect.y / window.innerHeight) > 0) setCurrent(ref.id)
            })
        }

        window.addEventListener('scroll', callback)
        return () => { window.removeEventListener('scroll', callback) }
    }, [])

    const prizeCard = (item) => {
        return (
            <div className='singularevent-prize-card-outer clip-class-prize'>
                <div className='singularevent-prize-card-inner clip-class-prize'>
                    <p style={{ color: '#2D48D5', fontWeight: '300', fontSize: window.innerWidth > 400 ? '0.9rem' : '0.7rem' }}>{item.position}</p>
                    <p style={{ color: '#2D48D5', fontWeight: '600', fontSize: window.innerWidth > 400 ? '1.4rem' : '0.9rem' }}>{item.prize}</p>
                </div>
            </div>
        )
    }



    return (
        <>
            <div className="singularevent-outer-wrapper">
                <div className="singularevent-title">
                    <div className='go-left-arrow'>
                        <Link to="/competitions"><img src={ArrowLeft} /></Link>
                    </div>
                    Circuit Simulation
                    <div className='go-left-arrow'>

                    </div>
                </div>
                <div className="singularevent-inner-wrapper">
                    <div className="singularevent-navbar">
                        <ul style={{ display: 'flex', listStyle: 'none', backgroundColor: '#F3FF63', justifyContent: 'space-evenly' }}>
                            <li onClick={() => { setCurrent('singularevent-overview') }}>
                                <a style={current === 'singularevent-overview' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#'>Overview</a></li>
                            <li onClick={() => { setCurrent('singularevent-event') }}>
                                <a style={current === 'singularevent-event' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#events-scroll-tracker'>Problem Statement</a></li>
                            <li onClick={() => { setCurrent('singularevent-registration') }}>
                                <a style={current === 'singularevent-registration' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#registration-scroll-tracker'>Registration</a></li>
                            <li onClick={() => { setCurrent('singularevent-prizes') }}>
                                <a style={current === 'singularevent-prizes' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#prizes-scroll-tracker'>Prizes</a></li>
                            <li onClick={() => { setCurrent('singularevent-contact') }}>
                                <a style={current === 'singularevent-contact' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Contact</a></li>
                            <li onClick={() => { setCurrent('singularevent-register') }}>
                                <a style={current === 'singularevent-register' ? { color: '#2D48D5', fontStyle: 'italic' } : { color: '#9c9c9c' }} href='#singularevent-contact'>Register</a></li>
                        </ul>
                    </div>
                    <div className="singularevent-content-outer">
                        <div className="singularevent-content-border clip-bg" style={{ backgroundColor: 'black', padding: '0px 4px 4px 4px' }}>
                            <div className="singularevent-content-inner clip-bg">
                                <div ref={overviewRef} id="singularevent-overview">
                                    <div className='singularevent-img-robo-wrapper'>
                                        <SmallImage title="Robowars" img={CircuitSimImg} />
                                    </div>
                                    <div className="singularevent-content-wrapper" style={{ margin: window.innerWidth > '650' ? '3vh 3vw' : '' }}>
                                        <div className="singularevent-content-heading">
                                            <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Overview
                                        </div>
                                        <div className="singularevent-content-details">
                                            <div id='events-scroll-tracker'></div>
                                            The Circuit Simulation Competition, a highlight of the Competitions module at Techniche, IIT Guwahati, challenges participants to demonstrate their electrical knowledge and innovative design solutions through complex circuit simulations, Encouraging creativity and technical skills among future engineers.
                                        </div>
                                    </div>
                                </div>
                                <div ref={eventRef} id="singularevent-event">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Problem Statement
                                    </div>
                                    <div className="singularevent-content-details">
                                        Design and build an analog waveform generator to display alphanumeric characters on an x-y controlled display, like an oscilloscope in x-y mode, using analog circuits to create the needed waveforms for the character display
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                    <div className="singularevent-content-details">
                                        The system should leverage analog circuits to produce the necessary waveforms for character display.
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                    <div className="singularevent-content-details">
                                        <b>Stage 1 (online): All registered teams are required to build a circuit and simulate it according to the given constraints outlined in the problem statement.</b>
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                    <div className="singularevent-content-details">
                                        <b>Stage 2 (offline): Shortlisted teams will receive the final round problem statement and must be submitted during Techniche.</b>
                                        <div id='registration-scroll-tracker'></div>
                                    </div>
                                </div>
                                <div ref={registrationRef} id="singularevent-registration">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Registration
                                    </div>
                                    <div className="singularevent-content-details" style={{ flexDirection: 'column' }}>
                                        <div style={{ margin: '20px 0px' }}>Registration for Circuit Simulation will be done through Unstop. <a href="https://unstop.com/competitions/circuit-simulation-competition-2024-techniche-iit-guwahati-1093990"><b>Click Here</b></a></div>

                                    </div>
                                </div>
                                <div ref={prizesRef} id="singularevent-prizes">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Prize Pool Worth Rs. 30,000/-
                                    </div>

                                    {/* <div style={{margin:'20px 0px'}}>
                                    <div>
                                        <div className='singularevent-prize-category-outer'>
                                            <div className='singularevent-prize-category-inner-1'>{prizeCard(prizes[0])}</div>
                                            <div className='singularevent-prize-category-inner-1' style={{display:'flex'}}>
                                                {prizeCard(prizes[1])}
                                            </div>
                                            <div className='singularevent-prize-category-inner-1' style={{display:'flex'}}>
                                                {prizeCard(prizes[2])}
                                            </div>
              
                                        </div>
                                    </div>
                                </div> */}



                                </div>
                                <div id="singularevent-schedule">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Schedule
                                    </div>
                                    <div className="singularevent-content-details">
                                        {schedule.map((item) => {
                                            return (
                                                <div key={item.id} style={{ margin: '10px' }}>
                                                    <div style={{ fontSize: '1.2rem', color: '#F3FF63', fontWeight: '500' }}>{item.date}{item.id < 3 && <hr />}</div>
                                                    <ul>
                                                        <li>{item.list[0]}</li>
                                                        <li>{item.list[1]}</li>
                                                        <li>{item.list[2]}</li>
                                                    </ul>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={contactRef} id="singularevent-contact">
                                    <div className="singularevent-content-heading">
                                        <img className='singularevent-vector' src={require('../../../../assets/EventsHead.png')} alt=" " />Contact
                                    </div>
                                    <div className="singularevent-content-details">
                                        {contacts.map((item) => {
                                            return (
                                                <div style={{ margin: '10px' }}>
                                                    <p style={{ fontWeight: '600' }}>{item.name}</p>
                                                    <p>{item.phone}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                                <div ref={registerRef} className="singularevent-register">
                                    {/* <Link to="https://unstop.com/competitions/glyder-competition-2024-techniche-iit-guwahati-1089225"> */}
                                    <div className='clip-btn singularevent-btn-outer' style={{ backgroundColor: 'black', width: 'max-content', padding: '2px' }}>
                                        <div className='clip-btn singularevent-btn-inner' style={{ backgroundColor: '#F3FF63', width: 'max-content', fontSize: '0.9rem', padding: '10px 40px' }}>Registrations Closed. Coming Soon for 2025!</div>
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default CircuitSimulation