import React, { useState, useEffect } from 'react';
import axios from 'axios';
import profile from './Images/image 73.png';
import ProfileCard from './ProfileCard.js';
import Navbar from '../../CampusAmbassdor/CA-Home/nav';
import './CADashboard.css';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

function CADashboard() {
    const [name, setName] = useState('');
    const [t_id, setT_id] = useState('');
    const [contact, setContact] = useState('');
    const [points, setPoints] = useState('');
    const [city, setCity] = useState('');
    const [institution, setInstitution] = useState('');
    const [email, setEmail] = useState('');
    const userEmail = localStorage.getItem('caemail'); // Retrieve email from localStorage
    console.log(userEmail);

    useEffect(() => {
        if (userEmail) {
            fetchUserDetails(userEmail);
        }
    }, [userEmail]); // Dependency array includes userEmail

    const fetchUserDetails = async (email) => {
        try {
            const response = await axios.post(baseURL + '/catasksupload/getuserdetails', { email });

            const { name, t_id, contact, points, email: userEmail, city, institution } = response.data;

            setName(name);
            setT_id(t_id);
            setContact(contact);
            setPoints(points);
            setCity(city);
            setInstitution(institution);
            setEmail(userEmail);

        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="container11">
            <div>
                <ProfileCard
                    name={name}
                    referral={t_id}
                    profileimg={profile}
                    email={email}
                    school={institution}
                    country={city}
                    contact={contact}
                    points={points}
                />
            </div>
        </div>
    );
}

export default CADashboard;
