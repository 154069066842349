import React, { useState } from 'react';
import "./SignUp.css"
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Header from '../../Header/header';
// import { environment } from '../../../constants/environment';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const SignUpForm = () => {

  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    password: '',
    country: '',
    state: '',
    city: '',
    age: '',
    password: '',
    confirm_password: ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post(baseURL + '/user/register', formData);
        console.log(response.data);
        navigate('/login');
    } catch (error) {
        if (error.response) {
            if (error.response.status === 409) {
                setErrorMessage('You are already registered. Please login.');
            } else if (error.response.status === 400) {
                if (error.response.data.message === 'Password and confirm password do not match') {
                    setErrorMessage('Password and confirm password do not match.');
                } else {
                    setErrorMessage('Registration failed. Please check your credentials.');
                }
            } else {
                setErrorMessage('Registration failed. Please try again.');
            }
        } else {
            setErrorMessage('Registration failed. Please try again.');
        }
    }
  };


  return (
    <div className='loginbackground'>
        <div className="bluebox">
        <p>Create an account</p>
        <form onSubmit={handleSubmit}>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Full Name</div>
              <input type="text" name="name" placeholder="Enter your full name" value={formData.name} onChange={handleChange} required/>
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Email</div>
              <input type="text" name="email" placeholder="Enter your email address" value={formData.email} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Contact</div> 
              <input type="text" name="contact" placeholder="Enter your contact number" value={formData.contact} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Age</div> 
              <input type="number" name="age" placeholder="Enter your age" value={formData.age} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Country</div> 
              <input type="text" name="country" placeholder="Enter your country" value={formData.country} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>State</div> 
              <input type="text" name="state" placeholder="Enter your state" value={formData.state} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>City</div> 
              <input type="text" name="city" placeholder="Enter your city" value={formData.city} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Password</div> 
              <input type="password" name="password" placeholder="Enter password" value={formData.password} onChange={handleChange} required />
            </div>
            <div className='input-feild-wrapper'>
              <div className='input-feild-indicator'>Confirm Passoword</div> 
              <input type="password" name="confirm_password" placeholder="Confirm password" value={formData.confirm_password} onChange={handleChange} required />
            </div>
            {errorMessage && <div className="error">{errorMessage}</div>}
            <div className='button-container'>
            <button type="submit">Create an account</button><br />
            <button type="button" style={{backgroundColor:'#F7F8FF', color:'#2D48D5'}}><Link to="/login" style={{color:'#2D48D5', fontWeight:'300'}}>Log In</Link></button>
            </div>
        </form>
        </div>
    </div>

  );
}

export default SignUpForm;