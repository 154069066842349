import i20L from '../../../assets/20L.png'
import i20Lh from '../../../assets/20Lh.png'
import i1L from '../../../assets/1L.png'
import i1Lh from '../../../assets/1Lh.png'
import i50K from '../../../assets/50K.png'
import i50Kh from '../../../assets/50Kh.png'
import i3x from '../../../assets/3x.png'
import i3xh from '../../../assets/3xh.png'
import i150K from '../../../assets/150K.png'
import i150Kh from '../../../assets/150Kh.png'
import { useState } from 'react'
import './style.css'
const ReachAbout = ()=>{

    const [hover , setHover]=useState(0);
    const StatElement = ({stat})=>{
        const src=hover===stat.id?stat.hover:stat.src
        return(
            <div className="reach-stat-card" 
            style={{width:'33vw'}}>
                <p style={{display:'flex',justifyContent:'center'}}>
                    <img src={src} alt='' className='reach-stat-img' style={{ width:window.innerWidth>700?((stat.id===2||stat.id===4)?'10.5vw':'20vw'):(stat.id==4?'24vw':(stat.id==2?'20vw':'40vw'))}}
                    onMouseEnter={()=>{setHover(stat.id)}}
                    onMouseLeave={()=>{setHover(0)}}/>
                </p>
                <p className='stat-txt' style={{color:'#2D48D5',textAlign:'center', fontWeight: '100'}}>{stat.txt}</p>    
            </div>
        )
    }

    return(
        <div className='reach-outer-wrapper' style={{display:'flex',flexDirection:'column',alignItems:'center'}}>

            <div className='our-reach-heading' style={{textAlign:'center',color:'#2D48D5'}}>
                Our <span style={{fontStyle:'italic',color:'#2D48D5'}}>Reach</span>
            </div>

            <div className='reach-inner-wrapper'>
                {/* <div style={{display:'flex', marginBottom:'10vh'}}> */}
                    <StatElement className='stat-data-wrapper' stat={{id:1 , src:i20L , hover:i20Lh, st:'20L' , txt:'Worth Prizes Distributed'}}/>
                    <StatElement className='stat-data-wrapper' stat={{id:2 , src:i1L , hover:i1Lh, st:'1L' , txt:'Lives Impacted'}}/>
                    <StatElement className='stat-data-wrapper' stat={{id:3 , src:i50K , hover:i50Kh, st:'50K' , txt:'Footfall'}}/>
                {/* </div> */}
                {/* <div style={{display:'flex'}}> */}
                    <StatElement className='stat-data-wrapper' stat={{id:4, src:i3x , hover:i3xh, st:'3x' , txt:'Year-over-year Growth'}}/>
                    <StatElement className='stat-data-wrapper' stat={{id:5, src:i150K , hover:i150Kh, st:'150K' , txt:'Likes on Meta'}}/>
                {/* </div> */}
            </div>

        </div>
    )
}
export default ReachAbout