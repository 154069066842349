const prizes = [
    {position:'Winner' , prize:'Rs.20,000/-'},
    {position:'1st Runner Up' , prize:'Rs.10,000/-'}
]
const contacts = [
    {name:'Veenas Jaiswal',phone:'+91 9120410232'},
    {name:'Sabika',phone:'+91 9419800232'},
    {name:'',phone:''},
]
const schedule  = [
    {item:1 , date:'15th August',list:['Registration Deadline']},
    {item:2 , date:'16th August',list:['Report Submission Start']},
    {item:3 , date:'20th August',list:['Report Submission End']},
    {item:4 , date:'30th August',list:['Final Presentation']},
]

export {prizes, contacts , schedule}