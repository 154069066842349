import React, { useEffect, useRef } from "react";
import "./HeroSection.css";

// Import images from the same folder
import image1 from "../GhmAssets/Star-hs.png";
import image2 from "../GhmAssets/Subtract-hs.png";
import image3 from "../GhmAssets/Vector.png";
// Import the image for Div 1
import div1Image from "../GhmAssets/FStar-hs.png";
// Import the image for Div 2
import div2Image from "../GhmAssets/Half-hs.png";
// Import the image for the "O" in "MARATHON"
import marathonO from "../GhmAssets/O-hs.png"; // <-- Change the filename as needed
import aftermovie from "../GhmAssets/af.mp4";
import ghmVideo from '../GhmAssets/ghm_vdo.mp4';

function HeroSection() {
  // Refs for the moving divs
  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);

  useEffect(() => {
    const handleMouseMove = (e) => {
      // Only apply on laptop/desktop
      if (window.innerWidth <= 768) return;

      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const offsetX = e.clientX - centerX;
      const offsetY = e.clientY - centerY;

      // Move 0.05x speed
      const moveHalf = (el) => {
        if (el) {
          el.style.transform = `translate(${offsetX * 0.05}px, ${offsetY * 0.05}px)`;
        }
      };

      // Move 0.02x speed
      const moveSlow = (el) => {
        if (el) {
          el.style.transform = `translate(${offsetX * 0.02}px, ${offsetY * 0.02}px)`;
        }
      };

      moveHalf(div1Ref.current);
      moveHalf(div2Ref.current);
      moveHalf(div3Ref.current);
      moveHalf(div4Ref.current);
      moveSlow(div5Ref.current);
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  return (
    <section className="hero-section mt-24">
      {/* Decorative Images */}
      <img src={image1} alt="Decorative 1" className="image image1" />
      <img src={image2} alt="Decorative 2" className="image image2" />
      <img src={image3} alt="Decorative 3" className="image image3" />

      {/* Div 1 with inner content */}
      <div ref={div1Ref} className="moving-div div1">
        <div className="div1-inner">
          <div className="div1-image-container">
            <img src={div1Image} alt="Logo" className="div1-image" />
          </div>
          <div className="div1-text-container">GUWAHATI</div>
        </div>
      </div>

      {/* Div 2 with inner content in column direction */}
      <div ref={div2Ref} className="moving-div div2">
        <div className="div2-inner">
          <div className="div2-image-container">
            <img src={div2Image} alt="Div2 Icon" className="div2-image" />
          </div>
          <div className="div2-text-container">Half</div>
        </div>
      </div>

      {/* Div 3: "MARATHON" with "O" as an image */}
      <div ref={div3Ref} className="moving-div div3">
        <div className="div3-inner">
          <div className="div3-part1">MARATH</div>
          <div className="div3-o-container">
            <img src={marathonO} alt="O" className="div3-o-image" />
          </div>
          <div className="div3-part2">N</div>
        </div>
      </div>

      {/* Div 4 updated with two child divs in a column */}
      <div ref={div4Ref} className="moving-div div4">
        <div className="div4-inner">
          <div className="div4-text1">Mark Your Calendars</div>
          <div className="div4-text2">13th APRIL</div>
        </div>
      </div>

      {/* Div 5 */}
      <video ref={div5Ref} className="moving-div div5" autoPlay loop muted playsInline>
         <source src={aftermovie} type="video/mp4" />
          Your browser does not support the video tag.
      </video>

    </section>
  );
}

export default HeroSection;

// import React, { useEffect, useRef } from "react";

// const HeroSection = () => {
//   const heroRef = useRef(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (!heroRef.current) return;
      
//       // Get the distance from the top of the viewport to the hero section
//       const rect = heroRef.current.getBoundingClientRect();
      
//       // Define the start and end scroll values (adjust these as needed)
//       const start = 0; // When hero's top reaches the viewport top
//       const end = 543; // Distance over which transition occurs
      
//       // Calculate the progress (0 to 1) based on scroll distance
//       const distanceScrolled = -rect.top;
//       let progress = (distanceScrolled - start) / (end - start);
//       progress = Math.min(Math.max(progress, 0), 1); // Clamp between 0 and 1
      
//       // Example: dynamically adjust hero height based on progress
//       const initialHeight = 943; // Starting height (modify as needed)
//       const finalHeight = 400;   // Ending height (modify as needed)
//       const currentHeight = initialHeight - (initialHeight - finalHeight) * progress;
//       heroRef.current.style.height = currentHeight + "px";
      
//       // You can add additional style transitions here
//       // For example, scale or fade out elements using the same progress value.
//       // e.g., someElement.style.transform = `scale(${1 - progress})`;
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, []);

//   return (
//     <section ref={heroRef} className="hero-section">
//       {/* Your Hero Section content goes here */}
//       <h1>Hero Section</h1>
//     </section>
//   );
// };

// export default HeroSection;
