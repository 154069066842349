import './Frames.css'
import framesPixel from '../../../assets/frames-pixel.png'
import frames1 from '../../../assets/frames1.png'
import frames2 from '../../../assets/frames2.png'
import frames3 from '../../../assets/frames3.png'
import frames4 from '../../../assets/frames4.png'
import frames5 from '../../../assets/frames5.png'
import frames6 from '../../../assets/frames6.png'
import frames7 from '../../../assets/frames7.png'
import frames8 from '../../../assets/frames8.png'
import frames9 from '../../../assets/frames9.png'
import frames10 from '../../../assets/frames10.png'
import frames11 from '../../../assets/frames11.png'
import frames12 from '../../../assets/frames12.png'
import frames13 from '../../../assets/frames13.png'
import frames14 from '../../../assets/frames14.png'
import frames15 from '../../../assets/frames15.png'
import frames16 from '../../../assets/frames16.png'
import frames17 from '../../../assets/frames17.png'
import frames18 from '../../../assets/frames18.png'
import frames19 from '../../../assets/frames19.png'
import frames20 from '../../../assets/frames20.png'
import frames21 from '../../../assets/frames21.png'
import frames22 from '../../../assets/frames22.png'
import frames23 from '../../../assets/frames23.png'
import frames24 from '../../../assets/frames24.png'
import frames25 from '../../../assets/frames25.png'
import { useEffect, useRef, useState } from 'react'
const FramesAbout = ()=>{
    const [index , setIndex]=useState(0);
    const [intervalsCtrl, setIntervalsCtrl] = useState({})
    const parentRef = useRef(null)
    const [isVisible , setIsVisible] = useState(false)
    const photos = [
        {url:frames1,id:1},
        {url:frames2,id:2},
        {url:frames3,id:3},
        {url:frames4,id:4},
        {url:frames5,id:5},
        {url:frames6,id:6},
        {url:frames7,id:7},
        {url:frames8,id:8},
        {url:frames9,id:9},
        {url:frames10,id:10},
        {url:frames11,id:11},
        {url:frames12,id:12},
        {url:frames13,id:13},
        {url:frames14,id:14},
        {url:frames15,id:15},
        {url:frames16,id:16},
        {url:frames17,id:17},
        {url:frames18,id:18},
        {url:frames9,id:19},
        {url:frames20,id:20},
        {url:frames21,id:21},
        {url:frames22,id:22},
        {url:frames23,id:23},
        {url:frames24,id:24},
        {url:frames25,id:25},
    ]

    useEffect(()=>{
        const observer = new IntersectionObserver((entries)=>{
            entries.forEach((entry)=>{
                if(entry.isIntersecting) setIndex(0)
                setIsVisible(entry.isIntersecting)
            })
        },{threshold:0.8})
        if(parentRef.current) observer.observe(parentRef.current)
        return()=>{
            if(parentRef.current) observer.unobserve(parentRef.current)
    }
    },[])

    useEffect(()=>{
        if(isVisible) handleClick({x:(window.innerWidth)/2-150,y:parentRef.current.offsetTop+200})
    },[isVisible])

    const handleClick = ({x,y})=>{
        console.log(index)
        const photo=photos[index]
        setIndex(index+1)
        if(index===photos.length-1)
            setIndex(0)

        let rotateDeg = 0
        // if (rotateDeg<5 && rotateDeg>-5){
        //     if (rotateDeg>0) rotateDeg+=0
        //     else rotateDeg-=0
        // }
        const photoElement = document.querySelector(`#frames-${photo.id}`)
        
        photoElement.style.left=`${x}px`
        photoElement.style.top=`${y}px`
        photoElement.style.opacity='1'
        photoElement.style.display='flex'
        photoElement.style.transform = `rotateZ(${rotateDeg}deg)`

        if(intervalsCtrl[photo.id]) clearInterval(intervalsCtrl[photo.id]);
        const photoInterval = setInterval(()=>{
            const currentTop=parseInt((photoElement.style.top).slice(0,(photoElement.style.top).length-2))
            const currentOpacity = parseFloat(photoElement.style.opacity)
            if(currentTop<((550*window.innerHeight)/100)) photoElement.style.opacity=`${currentOpacity-0.005}`
            if(photoElement.style.opacity===0){
                photoElement.style.display='none' 
                clearInterval(photoInterval)
            }       
            photoElement.style.top=`${currentTop-1}px`
        },10)
        setIntervalsCtrl((intervalsCtrl) => ({ ...intervalsCtrl, [photo.id]:photoInterval }))
    }

    return(
        <>
        {
        photos.map((photo)=>{
            return(
                <div id={`frames-${photo.id}`} className='frames-photo-card'>
                     <img className='frames-img' src={photo.url} alt='' style={{height:'auto',width:'auto'}}/>   
                </div>
            )
        })    
        }
        <div ref={parentRef} className="frames-outer-wrapper"
         onClick={(e)=>{handleClick({x:e.clientX,y:e.pageY})}}>
            <div className='frames-techniche-heading' style={{color:'white',textAlign:'center'}}>
                <span style={{fontStyle:'italic',color:'#F3FF63'}}>Frames </span>of Techniche
            </div>
            <div className='frames-techniche-subheading' style={{textAlign:'center',color:'#F3FF63'}}>
                Click on Screen to explore the Photos
            </div>
            <div className='frames-techniche-subheading' style={{position:'absolute',bottom:'-5px'}}>
                <div className='frames-techniche-subheading' style={{fontStyle:'italic',color:'white',textAlign:'center', marginBottom:'5vh'}}>
                    Photos from Team Production and Montage
                </div>
                <div>
                    <img src={framesPixel} alt='' style={{width:'100vw'}}/>
                </div>
            </div>
        </div>
        </>
    )
}
export default FramesAbout