import React from "react";
import "./21confirm.css";
import Tick from "../GhmAssets/confirm-tick.png";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

function SixConfirm() {
  const { ciphertext6 } = useParams();

  const bytes = CryptoJS.AES.decrypt(ciphertext6, "iitgtechniche");
  //const decryptedID = bytes.toString(CryptoJS.enc.Utf8);
  const decryptedID = ciphertext6.slice(-5);

  return (
    <div className="container-confirm">
      <div>
        <img src={Tick} alt="=="></img>
      </div>
      <h1>You have succesfully registered for Spirit run</h1>
      <p>Please check your mail to get your GHM RUNNER'S ID </p>
    </div>
  );
}
export default SixConfirm;
