import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import img1 from "./assets/image1.jpg";
import img2 from "./assets/image2.jpg";
import img3 from "./assets/image3.jpg";
import img4 from "./assets/image4.jpg";
import runner1 from "./assets/runners1.png";
import runner2 from "./assets/runners2.png";
import runner3 from "./assets/runners3.png";
import runner4 from "./assets/runners4.png";
import runner5 from "./assets/runners5.png";

const images = [img1, img2, img3, img4];

const MarathonPage = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Change every 2 seconds

        return () => clearInterval(interval);
    }, [index]);

    return (
        <div id="about" className="h-fit bg-[#fff6e4] flex items-center justify-center px-6 pt-20">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-20 max-w-6xl w-full min-h-[80vh]">
                {/* Left Section */}
                <div className="space-y-8 text-center md:text-left">
                    {/* Icons */}
                    <div className="relative flex items-center justify-center overflow-hidden w-full md:w-[280px] h-[140px]">
                        {images.map((src, i) => {
                            const pos = (i - index + images.length) % images.length;
                            return (
                                <motion.img
                                    key={i}
                                    src={src}
                                    alt={`image-${i}`}
                                    className="absolute w-[90px] h-[90px] rounded-full"
                                    style={{ zIndex: images.length - pos }}
                                    animate={{
                                        x: pos * 50 - 75 + "px",
                                        scale: pos === 0 ? 1.3 : 1,
                                        opacity: 1,
                                    }}
                                    transition={{ duration: 0.6, ease: "easeInOut" }}
                                />
                            );
                        })}
                    </div>

                    {/* Title */}
                    <h2 className="text-[#0e1f48]">
                        <em className="text-4xl font-light text-[#0e1f48]">Presenting</em> <br />
                        <span className="text-4xl text-[#0e1f48] font-bold">GUWAHATI HALF MARATHON</span>
                    </h2>

                    {/* Description */}
                    <p className="text-lg md:text-xl font-light text-[#0e1f48] leading-relaxed">
                        The Guwahati Half Marathon is an unwavering effort by Techniche, IIT Guwahati, to contribute to a social issue of relevance in our times. Through open conversation and mindful initiatives, we hope that this 21 km run, spanning the city, brings people together for active change. With every registration, we get closer to this year’s goal of creating a healthier society for Guwahati!
                    </p>

                    {/* Register Button */}
                    <div className="flex flex-col md:flex-row items-center md:items-start space-y-6 md:space-y-0 md:space-x-6 pt-4">
                        <Link to="/ghm/register">
                            <button className="bg-[#0e1f48] text-white text-lg md:text-xl px-8 py-3 font-semibold hover:bg-gray-800">
                                Register Now
                            </button>
                        </Link>
                        <span className="py-2 text-lg md:text-xl text-[#0e1f48]">+2000 already registered</span>
                    </div>
                </div>

                {/* Right Section (Image Grid) */}
                <div className="relative w-full max-w-screen-lg mx-auto min-h-screen flex items-center justify-center">
                    {/* Image Container */}
                    <div className="relative w-[300px] h-[450px] flex items-center justify-center">
                        {/* Center Image (On Top) */}
                        <img src={runner5}
                            className="w-[130px] h-[180px] object-cover object-center z-10 shadow-lg"
                            alt="center" />

                        {/* Top-left (Further left) */}
                        <img src={runner2}
                            className="absolute top-[-10px] left-[-60px] w-[200px] h-[300px] object-cover object-center z-0 shadow-md"
                            alt="top-left" />

                        {/* Bottom-left (More Zoomed) */}
                        <img src={runner3}
                            className="absolute bottom-[-40px] left-[-17px] w-[150px] h-[200px] object-cover object-[50%_30%] z-0 shadow-md"
                            alt="bottom-left" />

                        {/* Top-right (Further right) */}
                        <img src={runner4}
                            className="absolute top-[-40px] right-[-60px] w-[220px] h-[300px] object-cover object-center z-0 shadow-md"
                            alt="top-right" />

                        {/* Bottom-right (More Zoomed) */}
                        <img src={runner1}
                            className="absolute bottom-[-10px] right-[-10px] w-[180px] h-[200px] object-cover object-[50%_30%] z-0 shadow-md"
                            alt="bottom-right" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MarathonPage;

