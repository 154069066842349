const prizes = [
    {position:'Winner' , prize:'Rs.60,000/-'},
    {position:'1st Runner Up' , prize:'Rs.50,000/-'},
    {position:'2nd Runner Up' , prize:'Rs.40,000/-'}
]
const contacts = [
    {name:'Techniche IIT Guwahati',phone:'+91 87507 91896'},
    {name:'Vaibhav Bivesh',phone:'+91 96806 10046'},
    {name:'Pranshu Singh',phone:'+91 95326 86158'},
]
const schedule  = [
    {item:1 , date:'16th July',list:['Registration Deadline']},
    {item:2 , date:'17th July',list:['Round 1 starts']},
    {item:3 , date:'10th August',list:['Round 1 results']},
    {item:4 , date:'29th August',list:['Final Round']}
]

export {prizes, contacts , schedule}