import React, { useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Submission.css';
import { useAuth } from '../../../../services/authContext';

const baseURL = process.env.NODE_ENV === "production" ? "/api" : "http://localhost:3001/api";

const SubmissionAW = () => {
    const { sanitizedHash } = useParams();
    const [teamData, setTeamData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { userDataMain } = useAuth(); 
  
    return (
        <div>
        <div className='submission-container'>
            <div className='submission-heading'>
            <p>Click below to submit the SRE Report</p>
            </div>
            <div className='registered-button'>
                <Link to="https://docs.google.com/forms/d/e/1FAIpQLScIWDUeohE3Yj-TjO10kFDOTMVr0YPeZMk7kIh3EU8fzm2HWw/viewform?usp=sf_link"  className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200', paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >Submit SRE Report</Link><br />
            </div>
            
        </div>
        <div className='submission-container'>
            <div className='submission-heading'>
            <p>Click below to submit the video</p>
            </div>
            <div className='registered-button'>
                <Link to="https://docs.google.com/forms/d/e/1FAIpQLSeFIDPYk-ZA21CfVV0Ee6kErFdHDylnT9ZBS2oUgVdpdVPuuA/viewform?usp=sf_link" className='register transition delay-150 duration-300 ease-in-out bg-gradient-to-r from-blue-500 to-violet-900  hover:from-violet-900 hover:to-blue-500' style={{ fontSize: '1 rem' , fontWeight: '200', paddingTop:'2vh', paddingBottom:'2vh', paddingLeft:'2vw', paddingRight:'2vw', borderRadius:'5vh'}} >Submit Video</Link><br />
            </div>
            
        </div>
        </div>
      );
    };

export default SubmissionAW;
