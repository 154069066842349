import React, { useState } from 'react';
import './AllData.css';

const PASSWORD = "Techniche2024D@ta";

const Data = () => {
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if(password === PASSWORD){
            window.alert("Downloading File");
            
            // Downloading the file
            const link = document.createElement('a');
            link.href = 'https://techniche.org/api/data/give-me-the-data3646-daddy423';
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
        else {
            window.alert("Incorrect Password");
        }
    }

    return (
        <div className="container-for-data">
            <h1 className='h1-for-data'>Download All Data</h1>
            <form className='form-for-data' onSubmit={handleSubmit}>
                <div>
                    <label className='password-for-data' htmlFor='password-for-data'>Password: </label>
                    <input 
                        className='input-for-data'
                        type="password"
                        placeholder='Enter Password'
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                    />
                </div>
                <button className='button-for-data' type="submit-for-data">Validate and Download</button>
            </form>
        </div>
    )
}

export default Data;