import React from "react";
import { Link } from "react-router-dom";
import './SmallTile.css';
import { CIcon } from '@coreui/icons-react';
import { cilExternalLink} from '@coreui/icons';

const Tile = (props) => {
    return (
        <div className="SmallTileWrapper">
            <div className="SmallTileContainer">
                <Link to={`/${props.link}`}>
                <img className="SmallTileImage" src={props.img} alt="Blockchain" />
                <p style={{ color: props.color }}>{props.title}</p>
                <div className="LearnMoreWrapper">
                    <div className="LearnMoreContainer">
                        {props.text}
                    </div>
                    <CIcon className="LearnMoreicon" icon={cilExternalLink} />
                </div>
                </Link>
            </div>
        </div>
    );
}

export default Tile;
