import TeamCard from "./teamCard";
import { TeamData } from "./data";
import './style.css';
import { useEffect, useRef, useState } from "react";

const TeamAbout = () => {
    const [size, setSize] = useState(6);
    const [isVisible, setIsVisible] = useState(false);
    const parentTeamRef = useRef(null);
    const teamRef = useRef(null);
    const firstRef = useRef(true);

    useEffect(() => {
        const detailsBox = document.querySelector('.team-details-txt');
        if (size >= 8) detailsBox.style.opacity = '0';
        if (size < 8) detailsBox.style.opacity = '1';
    }, [size]);

    useEffect(()=>{
        const detailsBox = document.querySelector('.team-details-txt');
        const convenerObserver = new IntersectionObserver((entries)=>{
            entries.map((entry)=>{
               if(window.innerWidth<=500 && entry.isIntersecting) detailsBox.style.opacity = '0';
            })
        },{threshold:0.5})
        const convener = document.querySelector('#head-card-outer-0')
        if(convener) convenerObserver.observe(convener)
        return ()=>{if(convener) convenerObserver.unobserve(convener)}
    },[])

    const handleFontSize = () => {
        if (parentTeamRef.current){
            const offsetTop = parentTeamRef.current.offsetTop
            const scrollHeight = window.scrollY-parentTeamRef.current.clientHeight-1200
            //yeh 1200 because YearsAbout height insrese , so to adjust the motion here 
            const newFontSize = 1 + (2*scrollHeight/offsetTop) * 35;
            if (newFontSize >= 5 && newFontSize <= 20) setSize(newFontSize);
        }
    };

    //this working well
    const callbackTeam = () => {
        if (isVisible && parentTeamRef.current && teamRef.current) {
            handleFontSize();
            let percentage = ((window.scrollY - teamRef.current.offsetTop - 10) / window.innerHeight) * 100;
            percentage = Math.max(0, Math.min(200, percentage));
            
            const scroller = document.querySelector('.team-inner-wrapper');
            const title = document.querySelector('.who-team-inner');
            const detailsBox = document.querySelector('.team-details-txt');
            const firstTile = document.querySelector('#head-card-outer-0');
            const whoAreWe = document.querySelector('.who-team');
            
            if (window.innerWidth > 500) {
                const titleTranslate = percentage / 2; // Move the title slower
                scroller.style.transform = `translateX(${-percentage}vw)`;
                title.style.transform = `translateX(${-titleTranslate}vw)`;
            } else {
                // Calculate the position of the first tile and "who are we" text
                const tileRect = firstTile.getBoundingClientRect();
                const whoAreWeRect = whoAreWe.getBoundingClientRect();
                
                // Determine if the first tile is past the "who are we" text
                if (tileRect.top < whoAreWeRect.bottom) {
                    // Fade out when the tile has passed the "who are we" text
                    detailsBox.style.opacity = '0';
                } else {
                    // Calculate opacity based on scroll position
                    const fadeOutStart = whoAreWeRect.bottom - window.innerHeight * 0.5;
                    const fadeOutEnd = whoAreWeRect.bottom;
    
                    if (window.scrollY < fadeOutStart) {
                        // Fade in as we scroll back up
                        detailsBox.style.opacity = '1';
                    } else if (window.scrollY >= fadeOutStart && window.scrollY <= fadeOutEnd) {
                        // Fade out in the middle range
                        const fadeOutPercentage = (window.scrollY - fadeOutStart) / (fadeOutEnd - fadeOutStart);
                        detailsBox.style.opacity = 1 - fadeOutPercentage;
                    } else {
                        // Fully hidden after the end of fade-out range
                        detailsBox.style.opacity = '0';
                    }
                }
            }
        }
    };
    
      

    //this working well
    useEffect(() => {
        if(firstRef.current){
            firstRef.current = false;
            return
        }
        window.addEventListener('scroll', callbackTeam);
        return () => {
            window.removeEventListener('scroll', callbackTeam);
        };
    }, [isVisible]);

    //this working well
    useEffect(() => {
        const observerTeam = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                setIsVisible(entry.isIntersecting);
            });
        }, {threshold:0.2})
// 430vh : 0.2*430=86~100

        if (parentTeamRef.current) observerTeam.observe(parentTeamRef.current)
        return () => {
            if (parentTeamRef.current) observerTeam.unobserve(parentTeamRef.current)
        };
    }, []);

    return (
        <div ref={parentTeamRef} className="team-wrapper" style={{marginBottom:'20vh'}}>
            <div className="team-heading-wrapper" style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', position: 'sticky', top: '0px' }}>
                <div className="who-team" style={{fontSize: `${size}rem`, position: 'static',fontWeight:'100',overflow:'hidden'}}>
                    <div className="who-team-inner" style={{overflow:'visible',width:'max-content'}}>who are we?</div>
                </div>
                <div style={{width:'100vw',display:'flex',justifyContent:'center'}}>
                    <p className="team-details-txt" style={{ color: '#000000', fontSize: '1.2rem', textAlign: 'center', transition: '0.5s', fontWeight:'100'}}>
                        Get to know the passionate individuals who, along with a dedicated team, make Techniche possible.
                    </p>
                </div>
            </div>
            <div className="team-sticky-outer-wrapper" style={{ width: '100vw'}}></div>
                <div className="team-sticky-outer" ref={teamRef} >
                <div className="team-sticky-wrapper">
                    <div style={{overflowX:'hidden',transform:'translateX(0px)'}}>
                    <div className="team-inner-wrapper">
                        {TeamData.map((head, index) => (
                            <div className="head-card-outer-wrapper" id={`head-card-outer-${index}`} key={index} style={{transition:'2s', display: 'flex', alignItems: index % 2 === 0 ? 'flex-start' : 'flex-end' }}>
                                <TeamCard head={head}/>
                            </div>
                        ))}
                    </div>
                    </div>
                </div>
                </div>
         </div>
    );
};

export default TeamAbout;