import React, { useState } from 'react';
import Header from './components/Header/header';
import { Navigate, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import "./App.css"
import ScrollToTop from './services/ScrollToTop';

function App() {
  const location = useLocation();

  const isCanadianRoute = location.pathname.startsWith("/ca");
  const isCanadianRoute_ghm = location.pathname.startsWith("/ghm");
  const isCanadianRoute_newghm = location.pathname.startsWith("/new");


  return (
    <div className="App" >
      {!isCanadianRoute_newghm && !isCanadianRoute && !isCanadianRoute_ghm && <Header />}
      <ScrollToTop>
        <AppRoutes />
      </ScrollToTop>
    </div>
  );
}

export default App;
